import React from 'react';
import EndowmentPolicyDetails from '../../../../components/endowmentPolicy/endowmentPolicyDetails/EndowmentPolicyDetails';

const EndowmentPolicyDetailsPage = (props) => {
    return (
        <div>
            <EndowmentPolicyDetails {...props} />
        </div>
    );
};

export default EndowmentPolicyDetailsPage;
