import React from 'react'
import { FaArrowRightLong } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

const HealthTab = () => {
    const navigate = useNavigate()
    return (
        <div className='w-full rounded-md p-6'>
            <button className="w-1/2 mx-auto flex justify-center gap-2 text-lg p-2 shadow-md shadow-gray-600/90 
            rounded-xl text-white bg-orange-500 hover:bg-orange-700 transition duration-300"
                onClick={() => navigate('/health/health-details')}
            >
                Check Prices
                <FaArrowRightLong size={20} className='mt-1' />
            </button>
        </div>
    )
}

export default HealthTab