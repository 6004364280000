import { configureStore } from '@reduxjs/toolkit';
import memberReducer from '../features/member/memberSlice';
import tabReducer from '../features/tab/tabSlice';
import lifeTabReducer from '../features/lifeTab/lifeTabSlice';
import lifeReducer from '../features/life/lifeSlice';
import iciciBolE28E34Reducer from '../features/icici/iciciBolE28E34Slice';

export const store = configureStore({
    reducer: {
        member: memberReducer,
        tab: tabReducer,
        lifeTab: lifeTabReducer,
        life: lifeReducer,
        iciciBolE28E34: iciciBolE28E34Reducer,
    },
});