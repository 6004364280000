import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    typeState: '',
    termState: {
        fullName: '',
        dob: '',
        email: '',
        mobile: '',
        gender: '',
        maritalStatus: 'Single',
        occupationType: '',
        incomeSelection: '',
        educationalQualification: '',
        smokeDisclaimer: '',
        sumAssured: 5000000,
        modeOfPayment: 'Yearly',
        premiumPaymentTerm: '',
    },
    eBIResponse: {},
    endowmentState: {
        fullName: '',
        dob: '',
        email: '',
        mobile: '',
        gender: '',
        maritalStatus: 'Single',
        occupationType: '',
        incomeSelection: '',
        educationalQualification: '',
        smokeDisclaimer: '',
        sumAssured: 5000000,
        modeOfPayment: 'Yearly',
        premiumPaymentTerm: '10',
    },
};

const lifeSlice = createSlice({
    name: 'life',
    initialState,
    reducers: {
        setTypeState: (state, actions) => {
            return {
                ...state,
                typeState: actions.payload
            };
        },
        setTermState: (state, actions) => {
            return {
                ...state,
                termState: actions.payload
            };
        },
        resetTermState: (state) => {
            return {
                ...state,
                termState: initialState.termState
            };
        },
        setEbiResponse: (state, actions) => {
            return {
                ...state,
                eBIResponse: actions.payload
            };
        },
        setEndowmentState: (state, actions) => {
            return {
                ...state,
                endowmentState: actions.payload
            };
        },
        resetEndowmentState: (state) => {
            return {
                ...state,
                endowmentState: initialState.endowmentState
            };
        },
    },
});

export const {
    setTypeState,
    setTermState,
    resetTermState,
    setEbiResponse,
    setEndowmentState,
    resetEndowmentState,
} = lifeSlice.actions;

export default lifeSlice.reducer;