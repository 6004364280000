import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Router from './router/Router';
import Common from './helpers/common';
import Navbar from './components/navbar/Navbar';

const app = `max-w-screen min-h-screen mx-auto font-poppins relative bg-gradient-to-r from-[#fffdf8] via-[#fcf1cd] to-[#fdd7a2]`;

function App(props) {

  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);

  return (
    <div className={`${app}`}>
      <Navbar {...props} />
      <Router {...props} />
    </div>
  );
}

export default Common(App);
