import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setTabState } from '../../redux/features/tab/tabSlice';

import { FaArrowRightLong } from 'react-icons/fa6';
import { MdKeyboardArrowLeft } from 'react-icons/md';

const ContactDetails = (props) => {
    const { memberState } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const previousTab = () => {
        dispatch(setTabState('MEDICAL'));
    };

    const getPrice = () => {
        dispatch(setTabState('MEMBER'));
        navigate('/policy-details');
    };

    return (
        <>
            <h3 className='text-3xl text-black text-center'>Contact Details</h3>
            <div className='w-full h-full p-3 mt-3 flex flex-col items-center gap-5 '>
                <div className='relative'>
                    <div className='text-xs p-1 absolute left-3 -top-3 bg-white'>Your Fullname</div>
                    <input type='text' className='p-3 outline-none border-none rounded-lg' placeholder="John Doe" style={{ boxShadow: '1px 1px 3px #dbdbdb inset, -1px -1px 3px #4d4d4d inset' }} />
                </div>
                <div className='relative'>
                    <div className='text-xs p-1 absolute left-3 -top-3 bg-white'>Mobile No</div>
                    <input type='text' className='p-3 outline-none border-none rounded-lg' placeholder="1234567890" style={{ boxShadow: '1px 1px 3px #dbdbdb inset, -1px -1px 3px #4d4d4d inset' }} />
                </div>
                <div className='relative'>
                    <div className='text-xs p-1 absolute left-3 -top-3 bg-white'>City</div>
                    <input type='text' className='p-3 outline-none border-none rounded-lg' placeholder="City" style={{ boxShadow: '1px 1px 3px #dbdbdb inset, -1px -1px 3px #4d4d4d inset' }} />
                </div>
            </div>
            <div className='w-full mt-3 flex justify-between items-center'>
                <button type="button" className='p-3 rounded-full border border-gray-500' onClick={previousTab}>
                    <MdKeyboardArrowLeft size={20} />
                </button>
                <button
                    type='button'
                    className="w-1/4 flex justify-center gap-2 text-lg p-2 shadow-md shadow-gray-600/90 rounded-xl
                 text-white bg-orange-500 hover:bg-orange-700 transition duration-300 capitalize"
                    onClick={getPrice}
                >
                    Get Prices
                    <FaArrowRightLong size={20} className='mt-1' />
                </button>
            </div>
        </>

    );
};

export default ContactDetails;