import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTermState } from '../../redux/features/life/lifeSlice';
import { setTermTabState } from '../../redux/features/lifeTab/lifeTabSlice';
import { API_POST_LIFE_INSURANCE_QUOTE } from "../../config/ApiList";
import { Listbox, Transition } from '@headlessui/react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardArrowDown } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const nextButtonStyle = `w-3/5 ml-auto flex justify-center gap-2 text-base p-2 z-[49] shadow-md shadow-gray-600/90 rounded-xl 
                        text-white bg-orange-500 hover:bg-orange-700 transition duration-300 lg:w-1/2 lg:text-lg`;
const inputFieldStyle = `w-full text-lg pt-3 rounded-sm border-b border-gray-500/95 outline-none focus:border-blue-700`;
const errorMessageStyle = `mt-1 text-md text-red-500`;
const disabledBtnStyle = `bg-gray-400/60`;

const SumAssured = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const termState = useSelector(state => state.life.termState);
    const typeState = useSelector(state => state.life.typeState);
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const modeOfPaymentArr = [
        {
            id: 1,
            mode: 'Monthly'
        },
        {
            id: 2,
            mode: 'Quarterly'
        },
        {
            id: 3,
            mode: 'Yearly'
        },
    ];

    const amounts = [];
    let currentAmount = 5000000;

    while (currentAmount <= 100000000) {
        amounts.push(currentAmount);
        currentAmount += 500000;
    }

    const handleChange = (e, field) => {
        if (field === 'sumAssured') {
            dispatch(setTermState({ ...termState, sumAssured: e }));
        }
        if (field === 'modeOfPayment') {
            dispatch(setTermState({ ...termState, modeOfPayment: e }));
        }
        if (field === 'premiumPaymentTerm') {
            dispatch(setTermState({ ...termState, premiumPaymentTerm: e.target.value }));
        }
    };

    const validateForm = () => {
        const { premiumPaymentTerm } = termState;
        const errors = {};
        let isValid = true;

        if (!premiumPaymentTerm) {
            isValid = false;
            errors.premiumPaymentTerm = "Payment term is required";
        }

        setFormErrors(errors);
        return isValid;
    };

    const handlePrevious = () => {
        dispatch(setTermTabState('SMOKE'));
    };

    const handleNext = () => {
        if (!validateForm()) {
            return false;
        }
        setLoading(true);
        const termData = {
            lim_type: typeState,
            lim_full_name: termState.fullName,
            lim_dob: termState.dob,
            lim_gender: termState.gender,
            lim_sum_assured: termState.sumAssured,
            lim_premium_payment_term: termState.premiumPaymentTerm,
            lim_mode_of_payment: termState.modeOfPayment,
            lim_email: termState.email,
            lim_phone: termState.mobile,
            lim_occupation_type: termState.occupationType,
            lim_income: termState.incomeSelection,
            lim_education: termState.educationalQualification,
            lim_smoke_disclaimer: termState.smokeDisclaimer,
        };

        props.callRequest("POST", API_POST_LIFE_INSURANCE_QUOTE, false, termData).then((res) => {
            if (res.data.success) {
                const quotationId = res.data.quotation_id;
                setLoading(false);
                navigate(`/life-insurance/term-insurance/insurance-results/${quotationId}`);
            }
        }).catch((e) => {
            console.log(e);
        });
    };

    return (
        <>
            <div className='max-w-lg w-full h-full rounded-xl bg-white shadow-sm shadow-gray-700 lg:max-w-[30rem]'>
                <div className='w-full py-3 lg:py-10'>
                    <div className='w-full grid grid-cols-1 gap-5 px-1 mt-4 sm:px-3'>
                        <div className='w-full flex justify-between'>
                            <div className='w-1/2 px-3 flex items-center'>
                                <span>Sum assured amount</span>
                            </div>
                            <div className='w-1/2'>
                                <Listbox value={termState.sumAssured} onChange={(e) => handleChange(e, 'sumAssured')}>
                                    <div className="relative mt-1">
                                        <Listbox.Button className="relative w-11/12 cursor-default rounded-sm bg-white py-2 pl-3 pr-10 text-left shadow-sm shadow-gray-400 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                            <span className="block truncate"> {`₹ ${props.getCurrencyWithStringFormat(termState.sumAssured)}`}</span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <MdKeyboardArrowDown
                                                    className="h-5 w-5 text-black"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        </Listbox.Button>
                                        <Transition
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Listbox.Options className="absolute mt-1 z-50 max-h-60 w-11/12 overflow-auto rounded-md bg-white py-1 text-base shadow-md ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                                {amounts.map((amount, index) => (
                                                    <Listbox.Option
                                                        key={index}
                                                        className={({ active }) =>
                                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                                            }`
                                                        }
                                                        value={amount}
                                                    >
                                                        {({ selected }) => (
                                                            <>
                                                                <span
                                                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                                        }`}
                                                                >
                                                                    {`₹ ${props.getCurrencyWithStringFormat(amount)}`}
                                                                </span>
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </Listbox>
                            </div>
                        </div>

                        <div className='w-full flex justify-between'>
                            <div className='w-1/2 px-3 flex items-center'>
                                <span>Payment mode</span>
                            </div>
                            <div className='w-1/2'>
                                <Listbox value={termState.modeOfPayment} onChange={(e) => handleChange(e, 'modeOfPayment')}>
                                    <div className="relative mt-1">
                                        <Listbox.Button className="relative w-11/12 cursor-default rounded-sm bg-white py-2 pl-3 pr-10 text-left shadow-sm shadow-gray-400 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                            <span className="block truncate">{termState.modeOfPayment}</span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <MdKeyboardArrowDown
                                                    className="h-5 w-5 text-black"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        </Listbox.Button>
                                        <Transition
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Listbox.Options className="absolute mt-1 z-50 max-h-60 w-11/12 overflow-auto rounded-md bg-white py-1 text-base shadow-md ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                                {modeOfPaymentArr.map((item) => (
                                                    <Listbox.Option
                                                        key={item.id}
                                                        className={({ active }) =>
                                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                                            }`
                                                        }
                                                        value={item.mode}
                                                    >
                                                        {({ selected }) => (
                                                            <>
                                                                <span
                                                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                                        }`}
                                                                >
                                                                    {item.mode}
                                                                </span>
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </Listbox>
                            </div>
                        </div>

                        <div className="w-full px-3 mt-5 relative">
                            <div className='pr-2'>
                                <div className="absolute -top-4">Payment term (Years)</div>
                                <input
                                    type='text'
                                    name='premiumPaymentTerm'
                                    value={termState.premiumPaymentTerm || ''}
                                    onChange={(e) => handleChange(e, 'premiumPaymentTerm')}
                                    onKeyDown={props.handleKeyPress}
                                    className={`${inputFieldStyle}`}
                                    autoComplete='off'
                                    maxLength={2}
                                />
                            </div>
                            <small className={`${errorMessageStyle}`}>{!termState.premiumPaymentTerm && formErrors.premiumPaymentTerm}</small>
                        </div>
                    </div>

                    <div className='w-full px-2 mt-10 flex justify-center items-center lg:px-10'>
                        <div className='w-full flex gap-5 justify-between'>
                            <div className='w-1/2'>
                                <button type="button"
                                    className={`p-3 rounded-full border border-gray-500 ${loading && disabledBtnStyle}`}
                                    onClick={handlePrevious}
                                    disabled={loading}
                                >
                                    <MdKeyboardArrowLeft size={20} />
                                </button>
                            </div>

                            <div className='w-full h-full relative sm:w-1/3 lg:w-1/2'>
                                <div className='w-full hidden sm:block'>
                                    <button
                                        type="button"
                                        className={`${nextButtonStyle} ${loading && disabledBtnStyle}`}
                                        onClick={() => handleNext()}
                                        disabled={loading}
                                    >
                                        Next
                                        <MdKeyboardArrowRight size={20} className='mt-1' />
                                    </button>
                                </div>
                            </div>
                            <button type="button" className='p-2.5 rounded-full border border-orange-500 float-right bg-orange-500 text-white sm:hidden lg:p-3' onClick={() => handleNext()}>
                                <MdKeyboardArrowRight size={20} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SumAssured;
