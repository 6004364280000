import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    termTabState: 'BASIC',
    endowmentTabState: 'BASIC',
};

const lifeTabSlice = createSlice({
    name: 'lifeTab',
    initialState,
    reducers: {
        setTermTabState: (state, actions) => {
            return {
                ...state,
                termTabState: actions.payload
            };
        },
        setEndowmentTabState: (state, actions) => {
            return {
                ...state,
                endowmentTabState: actions.payload
            };
        }
    },
});

export const {
    setTermTabState,
    setEndowmentTabState,
} = lifeTabSlice.actions;

export default lifeTabSlice.reducer;