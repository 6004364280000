import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { IoCarSportSharp } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import { FaArrowRightLong } from "react-icons/fa6";
import CarDetailQuotes from './carDetailQuotes/CarDetailQuotes';

const CarDetails = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showQuotes, setShowQuotes] = useState(false);
    const [carState, setCarState] = useState({
        fullName: '',
        email: '',
        mobile: '',
    });
    const [formErrors, setFormErrors] = useState({});

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCarState({ ...carState, [name]: value });
    };

    const validateForm = () => {
        const { fullName, email, mobile } = carState;
        const errors = {};
        let isValid = true;

        if (!fullName) {
            isValid = false;
            errors.fullName = "Please enter a valid name";
        }

        if (!email) {
            isValid = false;
            errors.email = "Email is required";
        }

        if (!props.validateEmail(email)) {
            isValid = false;
            errors.is_Valid_email = "Please enter a valid email address";
        }

        if (!mobile) {
            isValid = false;
            errors.mobile = "Please enter mobile number";
        }

        setFormErrors(errors);
        return isValid;
    };

    const getQuotes = () => {
        if (!validateForm()) {
            return false;
        }
        setShowQuotes(true);
    };

    return (
        <>
            <div className='w-full min-h-[calc(100vh-6rem)] p-8'>
                <div className='container'>
                    <div className='w-full h-full px-7 py-4 '>
                        <h1 className='text-xl text-blue-700 font-nunito md:text-2xl lg:text-3xl'>Confirm Your Details & See Your Price</h1>
                        <div className='w-full h-44 lg:h-20 mt-7 flex flex-col rounded-xl bg-white shadow-md shadow-gray-500/50 lg:flex-row'>
                            <div className='w-full h-10 px-4 border-b border-dotted border-gray-400/90 sm:h-14 lg:w-1/4 lg:h-full lg:border-r'>
                                <IoCarSportSharp className='w-10 h-10 mx-auto -mt-1 text-blue-800/90 sm:w-14 sm:h-14 lg:w-20 lg:h-20 lg:mt-0.5' />
                            </div>
                            <div className='w-full h-20 px-2 py-2 sm:px-4 sm:py-5 lg:w-1/2 lg:py-2'>
                                <div className='uppercase text-black flex flex-col place-content-center text-sm sm:flex-row sm:text-base lg:text-lg lg:place-content-start'>
                                    <div className='px-4 sm:border-r-2 sm:border-gray-500/95'>
                                        <span>mahindra & mahindra</span>
                                    </div>
                                    <div className='px-4'>
                                        <span>xuv 500</span>
                                    </div>
                                </div>
                                <div className='mt-2 uppercase text-gray-500 grid grid-cols-1 gap-y-1 place-content-center text-xs sm:flex sm:text-base lg:text-lg lg:place-content-start'>
                                    <div className='px-4 sm:border-r-2 sm:border-gray-500/95'>
                                        <span>2000 cc</span>
                                    </div>
                                    <div className='px-4 sm:border-r-2 sm:border-gray-500/95'>
                                        <span>May 2015</span>
                                    </div>
                                    <div className='px-4'>
                                        <span>Diesel</span>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full h-full flex justify-center items-end sm:place-content-center lg:w-[calc(100%-(25%+50%))] lg:justify-start lg:items-center'>
                                <button
                                    className='flex gap-1 text-blue-700 hover:underline'
                                    onClick={toggleModal}
                                >
                                    <MdEdit className='w-[15px] h-[15px] mt-1 lg:w-[20px] lg:h-[20px]' />
                                    <span className='text-base lg:text-xl'>Edit Car</span>
                                </button>
                            </div>
                        </div>
                        {
                            !showQuotes ? (
                                <div className='w-full mt-5 rounded-xl bg-white shadow-md shadow-gray-500/50'>
                                    <div className='p-5'>
                                        <div className='flex flex-col gap-4 lg:flex-row'>
                                            <div className='w-full mx-auto grid grid-cols-1 gap-5 mt-5 sm:w-[70%] md:w-3/4 lg:grid-cols-2'>
                                                <div className='flex flex-col gap-y-3 relative font-nunito font-semibold'>
                                                    <div className='px-1 absolute -top-3 left-5 bg-white xs:text-sm xs:-top-2.5'>Full Name</div>
                                                    <input
                                                        type='text'
                                                        name='fullName'
                                                        value={carState.fullName}
                                                        placeholder='Enter your full name'
                                                        className='p-3 rounded-md border border-gray-500/95 outline-none focus:border-blue-700 lg:p-4'
                                                        onChange={handleChange}
                                                    />
                                                    <small className='text-md text-red-500'>{!carState.fullName && formErrors.fullName}</small>
                                                </div>
                                                <div className='flex flex-col gap-y-3 relative font-nunito font-semibold'>
                                                    <div className='px-1 absolute -top-3 left-5 bg-white xs:text-sm xs:-top-2.5'>Email</div>
                                                    <input
                                                        type='text'
                                                        name='email'
                                                        value={carState.email}
                                                        placeholder='Enter your email'
                                                        className='p-3 rounded-md border border-gray-500/95 outline-none focus:border-blue-700 lg:p-4'
                                                        onChange={handleChange}
                                                    />
                                                    <small className='text-md text-red-500'>{!carState.email ? formErrors.email : !props.validateEmail(carState.email) ? formErrors.is_Valid_email : ''}</small>
                                                </div>
                                                <div className='flex flex-col gap-y-3 relative font-nunito font-semibold'>
                                                    <div className='px-1 absolute -top-3 left-5 bg-white xs:text-sm xs:-top-2.5'>Mobile No</div>
                                                    <input
                                                        type='text'
                                                        name='mobile'
                                                        value={carState.mobile}
                                                        placeholder='Enter your mobile no'
                                                        className='p-3 rounded-md border border-gray-500/95 outline-none focus:border-blue-700 lg:p-4'
                                                        onChange={handleChange}
                                                        onKeyDown={props.handleKeyPress}
                                                        maxLength={10}
                                                    />
                                                    <small className='text-md text-red-500'>{!carState.mobile && formErrors.mobile}</small>
                                                </div>
                                            </div>
                                            <div className='w-full flex lg:w-[calc(100%-60%)] lg:justify-center lg:items-center'>
                                                <button
                                                    className="w-full mx-auto flex justify-center gap-2 text-lg py-2 shadow-md shadow-gray-600/90 rounded-xl
                                     text-white bg-orange-500 hover:bg-orange-700 transition duration-300 sm:w-[70%] sm:p-3 md:w-3/4 md:p-3 lg:w-1/2 lg:p-3"
                                                    onClick={getQuotes}
                                                >
                                                    Get Price
                                                    <FaArrowRightLong size={20} className='mt-1' />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <CarDetailQuotes {...props} />
                            )
                        }
                    </div>
                </div>
            </div >

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={toggleModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md relative transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all lg:w-3/4">
                                    <div className='w-full h-14 bg-gray-200/80 lg:h-20'>
                                        <div className='w-full h-full px-4 py-2'>
                                            <div className='text-xs uppercase flex text-black lg:text-sm'>
                                                <div className='px-4 border-r-2 border-gray-500/95'>
                                                    <span className='font-bold lg:font-semibold'>mahindra & mahindra</span>
                                                </div>
                                                <div className='px-4'>
                                                    <span className='font-bold lg:font-semibold'>xuv 500</span>
                                                </div>
                                            </div>
                                            <div className='mt-2 uppercase flex text-gray-500 text-xs lg:text-md'>
                                                <div className='px-4 border-r-2 border-gray-500/95'>
                                                    <span>2000 cc</span>
                                                </div>
                                                <div className='px-4 border-r-2 border-gray-500/95'>
                                                    <span>May 2015</span>
                                                </div>
                                                <div className='px-4'>
                                                    <span>Diesel</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='p-5'>
                                        <div className='flex flex-col gap-4'>
                                            <div className='flex flex-col gap-y-3 relative font-nunito font-semibold'>
                                                <div className='px-1 text-sm absolute -top-2.5 left-5 bg-white lg:text-base lg:-top-3'>Field 1</div>
                                                <input type='text' placeholder='Field 1' className='p-3 rounded-md border border-gray-500/95 outline-none focus:border-blue-700 lg:p-4' />
                                            </div>
                                            <div className='flex flex-col gap-y-3 relative font-nunito font-semibold'>
                                                <div className='px-1 text-sm absolute -top-2.5 left-5 bg-white lg:text-base lg:-top-3'>Field 2</div>
                                                <input type='text' placeholder='Field 2' className='p-3 rounded-md border border-gray-500/95 outline-none focus:border-blue-700 lg:p-4' />
                                            </div>
                                            <button href="#" className="w-full flex font-semibold justify-center gap-2 text-lg p-3 rounded-xl
                                             text-white bg-orange-500 hover:bg-orange-700 transition duration-300 xs:py-2">
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default CarDetails;