import React, { useEffect, useState } from 'react';
import { BsArrowLeftCircle } from "react-icons/bs";
import { useNavigate, useParams } from 'react-router-dom';
import { setTermTabState } from '../../../redux/features/lifeTab/lifeTabSlice';
import { useDispatch } from 'react-redux';

import PlanDetails from '../../common/PlanDetails';
import { API_GET_LIFE_INSURANCE_QUOTE } from '../../../config/ApiList';
import { ThreeCircles } from 'react-loader-spinner';
import useSWRFetch from '../../../hooks/useSWRFetch';
import { setEbiResponse } from '../../../redux/features/life/lifeSlice';

const policy_icon = '/images/policy_icons/ICICI_Prudential.avif';

const TermInsuranceDetails = (props) => {
    const { quotationId } = useParams();
    const url = `${API_GET_LIFE_INSURANCE_QUOTE}/${quotationId}`;
    const { data, error, isLoading } = useSWRFetch(url);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [eBIResponse, setEBIResponse] = useState({});
    const eBIResponseTemp = data?.data;

    useEffect(() => {
        if (!isLoading) {
            const ebiResponseData = JSON.parse(eBIResponseTemp);
            setEBIResponse(ebiResponseData);
            dispatch(setEbiResponse(ebiResponseData));
        }
    }, [isLoading]);

    const handleViewAllPlans = () => {
        navigate(`/life-insurance/term-insurance/insurance-results/${quotationId}`);
        dispatch(setTermTabState('SUM_ASSURED'));
    };

    return (
        <>
            {
                isLoading ? (
                    <div className='w-full h-full relative' >
                        <div className='fixed z-50 inset-0 bg-gradient-to-r from-[#fffdf8] via-[#fcf1cd] to-[#fdd7a2]'>
                            <div className='w-full h-full flex justify-center items-center'>
                                <ThreeCircles
                                    visible={isLoading}
                                    height="50"
                                    width="50"
                                    outerCircleColor="#f97316"
                                    middleCircleColor="transparent"
                                    innerCircleColor="#1d4fd8"
                                    ariaLabel="three-circles-loading"
                                />
                            </div>
                        </div>
                    </div >
                ) : (
                    <div className='w-full min-h-[calc(100vh-6rem)]'>
                        <div className='w-full h-10 bg-white border-b shadow-sm'>
                            <div className='container w-full h-full px-16'>
                                <button className='p-2 flex gap-2 group' onClick={() => handleViewAllPlans()}>
                                    <BsArrowLeftCircle size={20} className='mt-0.5 group-hover:-translate-x-1 transition duration-300' />
                                    <span className='font-nunito'>View all Plans</span>
                                </button>
                            </div>
                        </div>
                        <div className='w-full'>
                            <div className='container w-full px-16 flex gap-2 mt-7'>
                                <div className='w-[70%] p-3 flex flex-col gap-y-6'>
                                    <div className='w-full h-24 px-8 py-5 flex justify-between bg-white rounded-md shadow-sm shadow-gray-700'>
                                        <div className='w-1/5 my-auto'>
                                            <img src={`${policy_icon}`} alt='' width={175} className='block' />
                                        </div>
                                        <div className='w-3/5 my-auto'>
                                            <span className='text-xl text-gray-500'>{eBIResponse?.PremiumSummary?.ProductName}</span>
                                        </div>
                                    </div>
                                    <div className='w-full min-h-10 px-8 py-5 bg-white rounded-md shadow-sm shadow-gray-700'>
                                        <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Impedit accusantium autem odit explicabo, dolore id sit beatae,
                                            ipsa ratione sequi enim placeat cumque, doloremque tenetur officiis eveniet quos fuga ullam possimus sunt
                                            ut? Itaque, enim. Ipsam vel deserunt doloribus, accusantium reprehenderit veniam maxime modi beatae quas
                                            in quasi error voluptatibus?
                                        </span>
                                    </div>
                                </div>
                                <div className='w-[30%] p-3 flex flex-col gap-y-6'>
                                    <PlanDetails {...props} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default TermInsuranceDetails;