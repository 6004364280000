import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FaUserCircle } from "react-icons/fa";
import { MdEdit } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { setEndowmentTabState } from '../../../redux/features/lifeTab/lifeTabSlice';
import { API_GET_LIFE_INSURANCE_QUOTE } from '../../../config/ApiList';
import { ThreeCircles } from 'react-loader-spinner';
import useSWRFetch from '../../../hooks/useSWRFetch';
import { setEbiResponse, setEndowmentState, setTypeState } from '../../../redux/features/life/lifeSlice';

const userInfoStyle = `px-3 border-r border-gray-500`;
const termDataStyle = `w-1/4 h-full flex flex-col justify-center items-center`;

const policy_icon = '/images/policy_icons/ICICI_Prudential.avif';

const EndowmentPolicyQuotes = (props) => {
    const { quotationId } = useParams();
    const url = `${API_GET_LIFE_INSURANCE_QUOTE}/${quotationId}`;
    const { data, isLoading } = useSWRFetch(url);
    const endowmentState = useSelector(state => state.life.endowmentState);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [eBIResponse, setEBIResponse] = useState([]);
    const eBIResponseTemp = data?.data;
    const lifeInsuranceData = data?.lifeInsuranceData;

    useEffect(() => {
        if (!isLoading) {
            const parsedEbiResponse = eBIResponseTemp.map((item) => {
                const temp = JSON.parse(item);
                return temp;
            });

            setEBIResponse(parsedEbiResponse);
            dispatch(setEbiResponse(parsedEbiResponse));
            dispatch(setTypeState(lifeInsuranceData.lim_type));
            dispatch(setEndowmentState({
                ...endowmentState,
                fullName: lifeInsuranceData.lim_full_name,
                dob: lifeInsuranceData.lim_dob,
                email: lifeInsuranceData.lim_email,
                mobile: lifeInsuranceData.lim_phone,
                gender: lifeInsuranceData.lim_gender,
                maritalStatus: 'Single',
                occupationType: lifeInsuranceData.lim_occupation_type,
                incomeSelection: lifeInsuranceData.lim_income,
                educationalQualification: lifeInsuranceData.lim_education,
                smokeDisclaimer: lifeInsuranceData.lim_smoke_disclaimer,
                sumAssured: lifeInsuranceData.lim_sum_assured,
                modeOfPayment: lifeInsuranceData.lim_mode_of_payment,
                premiumPaymentTerm: lifeInsuranceData.lim_premium_payment_term,
            }));
        }
    }, [isLoading, eBIResponseTemp, lifeInsuranceData, dispatch]);

    const editDetails = () => {
        navigate("/life-insurance/endowment-policy");
        dispatch(setEndowmentTabState('BASIC'));
    };

    const viewDetails = (productCode) => {
        navigate(`/life-insurance/endowment-policy/endowment-details/${quotationId}/${productCode}`);
    };

    return (
        <>
            {
                isLoading ? (
                    <div className='w-full h-full relative'>
                        <div className='fixed z-50 inset-0 bg-gradient-to-r from-[#fffdf8] via-[#fcf1cd] to-[#fdd7a2]'>
                            <div className='w-full h-full flex justify-center items-center'>
                                <ThreeCircles
                                    visible={isLoading}
                                    height="50"
                                    width="50"
                                    outerCircleColor="#f97316"
                                    middleCircleColor="transparent"
                                    innerCircleColor="#1d4fd8"
                                    ariaLabel="three-circles-loading"
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='w-full min-h-[calc(100vh-6rem)]'>
                        <div className='w-full h-10 px-10 py-2 bg-white border-b shadow-sm'>
                            <div className='text-sm flex justify-end'>
                                <FaUserCircle size={25} color='skyblue' />
                                <span className={`${userInfoStyle}`}>{lifeInsuranceData?.lim_full_name}</span>
                                <span className={`${userInfoStyle}`}>{props.calculateAge(lifeInsuranceData?.lim_dob)} yrs</span>
                                <span className={`${userInfoStyle}`}>{lifeInsuranceData?.lim_gender}</span>
                                <span className={`${userInfoStyle}`}>
                                    {lifeInsuranceData?.lim_smoke_disclaimer === "Yes" ? "Smoker" : "Non-smoker"}
                                </span>

                                <button
                                    className='flex gap-1 ml-3 text-blue-700 hover:underline'
                                    onClick={() => editDetails()}
                                >
                                    <MdEdit className='w-[15px] h-[15px] mt-1' />
                                    <span className='text-base'>Edit</span>
                                </button>
                            </div>
                        </div>
                        <div className='w-full min-h-full px-10 py-2 mt-5'>
                            <div className='w-3/4 h-40 p-5 mb-5 mx-auto flex gap-5 bg-[#fff4e7] rounded-md shadow-sm shadow-gray-700'>
                                <div className={`${termDataStyle}`}>
                                    <img src={`${policy_icon}`} alt='' width={175} className='block' />
                                    <span className='text-gray-400 text-center mt-4'>{eBIResponse[0]?.PremiumSummary?.ProductName}</span>
                                </div>
                                <div className='w-1/2 px-4 flex gap-4 justify-start'>
                                    <div className="w-2/5 p-3 border-r border-dotted border-gray-500">
                                        <div className='w-3/4 flex flex-col'>
                                            <span className='text-lg font-nunito mb-2'>You Invest</span>
                                            <span>{`₹ ${props.getCurrencyWithStringFormat(eBIResponse[0]?.PremiumSummary?.AnnualPremium * 10)}`}</span>
                                            <span className='px-2 py-1 mt-1 rounded-md text-xs text-center text-blue-700 bg-gray-300'>in 10 years</span>
                                        </div>
                                    </div>
                                    <div className='w-3/5 p-3'>
                                        <div className="flex flex-col">
                                            <div className='mb-2 flex gap-3'>
                                                <span className='text-lg font-nunito'>You Get</span>
                                                <span className='px-2 py-1 mt-0.5 rounded-md text-xs text-center text-blue-700 bg-gray-300'>
                                                    {`${props.roundToDecimal((eBIResponse[0]?.PremiumSummary?.GuaranteedIncome) / ((eBIResponse[0]?.PremiumSummary?.AnnualPremium) * 10))}`}{" "}times
                                                </span>
                                            </div>
                                            <span>{`₹ ${props.getCurrencyWithStringFormat(eBIResponse[0]?.PremiumSummary?.GuaranteedIncome)}`}</span>
                                            <span className='text-gray-500 font-poppins'>&nbsp;({eBIResponse[0]?.PremiumSummary?.DeathBenefitOption} Payout)</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-[calc(100%-(25%+50%))] flex flex-col justify-center items-end">
                                    <span className='text-xl'>{`${props.getCurrencyFormat(eBIResponse[0]?.PremiumSummary?.AnnualPremiumWithTax)}`}</span>
                                    <span className='text-xs text-gray-500 mb-4'>{`${eBIResponse[0]?.PremiumSummary?.ModeOfPayment} Premium with tax`}</span>
                                    <button
                                        onClick={() => viewDetails(eBIResponse[0]?.PremiumSummary?.ProductCode)}
                                        className='text-sm p-3 rounded-md bg-blue-700 text-white hover:bg-blue-800/95 transition duration-300'
                                    >
                                        View Details
                                    </button>
                                </div>
                            </div>

                            <div className='w-3/4 h-40 p-5 mb-5 mx-auto flex gap-5 bg-[#fff4e7] rounded-md shadow-sm shadow-gray-700'>
                                <div className={`${termDataStyle}`}>
                                    <img src={`${policy_icon}`} alt='' width={175} className='block' />
                                    <span className='text-gray-400 text-center mt-4'>{eBIResponse[1]?.PremiumSummary?.ProductName}</span>
                                </div>
                                <div className='w-1/2 px-4 flex gap-4 justify-start'>
                                    <div className="w-2/5 p-3 border-r border-dotted border-gray-500">
                                        <div className='w-3/4 flex flex-col'>
                                            <span className='text-lg font-nunito mb-2'>You Invest</span>
                                            <span>{`₹ ${props.getCurrencyWithStringFormat(eBIResponse[1]?.PremiumSummary?.AnnualPremium * 10)}`}</span>
                                            <span className='px-2 py-1 mt-1 rounded-md text-xs text-center text-blue-700 bg-gray-300'>in 10 years</span>
                                        </div>
                                    </div>
                                    <div className='w-3/5 p-3'>
                                        <div className="flex flex-col">
                                            <div className='mb-2 flex gap-3'>
                                                <span className='text-lg font-nunito'>You Get</span>
                                                <span className='px-2 py-1 mt-0.5 rounded-md text-xs text-center text-blue-700 bg-gray-300'>
                                                    {`${props.roundToDecimal((eBIResponse[1]?.PremiumSummary?.EstimatedTotalMaturityBenefit10) / ((eBIResponse[1]?.PremiumSummary?.AnnualPremium) * 10))}`}{" "}times
                                                </span>
                                            </div>
                                            <span>{`₹ ${props.getCurrencyWithStringFormat(eBIResponse[1]?.PremiumSummary?.EstimatedTotalMaturityBenefit10)}`}</span>
                                            <span className='text-gray-500 font-poppins'>&nbsp;({eBIResponse[1]?.PremiumSummary?.DeathBenefitOption} Payout)</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-[calc(100%-(25%+50%))] flex flex-col justify-center items-end">
                                    <span className='text-xl'>{`${props.getCurrencyFormat(eBIResponse[1]?.PremiumSummary?.AnnualPremiumWithTax)}`}</span>
                                    <span className='text-xs text-gray-500 mb-4'>{`${eBIResponse[1]?.PremiumSummary?.ModeOfPayment} Premium with tax`}</span>
                                    <button
                                        onClick={() => viewDetails(eBIResponse[1]?.PremiumSummary?.ProductCode)}
                                        className='text-sm p-3 rounded-md bg-blue-700 text-white hover:bg-blue-800/95 transition duration-300'
                                    >
                                        View Details
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default EndowmentPolicyQuotes;