import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { API_GET_LIFE_INSURANCE_PRODUCT_DETAIL } from '../../config/ApiList';
import useSWRFetch from '../../hooks/useSWRFetch';
import { useSelector } from 'react-redux';

const userDetailsStyle = `font-bold capitalize`;

const policy_icon = '/images/policy_icons/ICICI_Prudential.avif';

const PlanDetails = (props) => {
    const { quotationId, planId } = useParams();
    const url = `${API_GET_LIFE_INSURANCE_PRODUCT_DETAIL}/${quotationId}/${planId}`;
    const { data, isLoading } = useSWRFetch(url);
    const typeState = useSelector(state => state.life.typeState);
    const navigate = useNavigate();
    const location = useLocation();
    const [eBIResponse, setEBIResponse] = useState({});
    const eBIResponseTemp = data?.data;
    const lifeInsuranceData = data?.lifeInsuranceData;

    useEffect(() => {
        if (!isLoading) {
            const ebiResponseData = JSON.parse(eBIResponseTemp);
            setEBIResponse(ebiResponseData);
        }
    }, [isLoading]);

    const handleBuyNow = () => {
        if (typeState === 'Term') {
            navigate(`/life-insurance/term-insurance/insurance-pre-ebi/${quotationId}/${planId}`);
        } else if (typeState === 'Endowment') {
            navigate(`/life-insurance/endowment-policy/checkout/${quotationId}/${planId}`);
        }
    };

    return (
        <>
            <div className='w-full min-h-10 p-3 bg-white rounded-md shadow-sm shadow-gray-700'>
                {
                    location.pathname.includes('insurance-details') || location.pathname.includes('endowment-details') ? (
                        <>
                            <div className='w-full py-2 border-b border-dotted border-gray-700'>
                                <span className='font-nunito'>User Details</span>
                            </div>
                            <div className='w-full py-2 font-nunito text-sm'>
                                Policy for <span className={`${userDetailsStyle}`}>{eBIResponse?.FirstName} {' '} {eBIResponse?.LastName}</span>{','}{' '}
                                <span className={`${userDetailsStyle}`}>{props.calculateAge(eBIResponse?.DateOfBirth)}</span>{' '}years old{','}{' '}
                                <span className='font-semibold'>{eBIResponse?.PremiumSummary?.Tobacco === "1" ? "Smoker" : "Non-smoker"}</span>{','}{' '}
                                <span className={`${userDetailsStyle}`}>{eBIResponse?.Gender}</span>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='flex justify-between place-content-center'>
                                <div className='my-auto'>
                                    <img src={`${policy_icon}`} alt='' width={125} className='block' />
                                </div>
                                <div className='flex flex-col gap-y-1'>
                                    <span className='text-gray-700'>{eBIResponse?.PremiumSummary?.ProductName}</span>
                                    <span>{`${props.getCurrencyWithStringFormat(eBIResponse?.PremiumSummary?.TotalPremium)}`}{" "}<span className='text-gray-500'>{`${eBIResponse?.PremiumSummary?.ModeOfPayment}`}</span></span>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
            <div className='w-full p-3 bg-white rounded-md shadow-sm shadow-gray-700'>
                <div className='w-full min-h-0 py-2 border-b border-dotted border-gray-700'>
                    <span className='font-nunito'>Policy Details</span>
                </div>
                <div className='py-3 flex flex-col gap-y-3'>
                    <div className='text-sm flex justify-between'>
                        <span className='capitalize text-gray-700'>order id</span>
                        <span>{quotationId}</span>
                    </div>
                    <div className='text-sm flex justify-between'>
                        <span className='capitalize text-gray-700'>policy term</span>
                        <span>{eBIResponse?.PremiumSummary?.Term} years</span>
                    </div>
                    <div className='text-sm flex justify-between'>
                        <span className='capitalize text-gray-700'>premium payment term</span>
                        <span>{eBIResponse?.PremiumSummary?.PremiumPaymentTerm} years</span>
                    </div>
                    <div className='text-sm flex justify-between'>
                        <span className='capitalize text-gray-700'>total payout</span>
                        <span>{`${props.getCurrencyWithStringFormat(eBIResponse?.PremiumSummary?.GuaranteedSumAssuredScen1)}`}</span>
                    </div>
                    {
                        eBIResponse?.PremiumSummary?.RiderDetails?.Rider?.length > 0 && (
                            <>
                                <span className='text-sm text-blue-700'>Riders:</span>
                                {
                                    eBIResponse?.PremiumSummary?.RiderDetails?.Rider.map((item, i) => (
                                        <div key={i} className='text-sm'>
                                            <span className='text-gray-700'>{item.Name}</span>
                                        </div>
                                    ))
                                }
                            </>
                        )
                    }
                </div>
            </div>
            <div className='w-full min-h-10 p-3 bg-white rounded-md shadow-sm shadow-gray-700'>
                <div className='w-full py-2 border-b border-dotted border-gray-700'>
                    <span className='font-nunito'>Payment Details</span>
                </div>
                <div className='py-3 flex flex-col gap-y-3 border-b border-dotted border-gray-700'>
                    <div className='text-sm flex justify-between'>
                        <span className='capitalize text-gray-700'>base premium</span>
                        <span>{props.getCurrencyFormat(eBIResponse?.PremiumSummary?.BasePremium)}</span>
                    </div>
                    <div className='text-sm flex justify-between'>
                        <span className='capitalize text-gray-700'>Taxes (18%)</span>
                        <span>{props.getCurrencyFormat(eBIResponse?.PremiumSummary?.ServiceTax)}</span>
                    </div>
                </div>
                <div className='py-3'>
                    <div className='flex justify-between text-lg'>
                        <div className='flex flex-col'>
                            <span className='capitalize'>total premium</span>
                            <span>(incl. tax)</span>
                        </div>
                        <span>{`${props.getCurrencyFormat(eBIResponse?.PremiumSummary?.AnnualPremiumWithTax)}`}</span>
                    </div>
                </div>
                {
                    location.pathname.includes('insurance-details') || location.pathname.includes('endowment-details') ? (
                        <div className='py-3'>
                            <button
                                className='w-full h-full p-3 bg-blue-700 text-white rounded-md shadow-sm shadow-gray-700'
                                onClick={() => handleBuyNow()}
                            >
                                Buy Now
                            </button>
                        </div>
                    ) : null
                }
            </div>
        </>
    );
};

export default PlanDetails;
