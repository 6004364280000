import React from 'react';

import EndowmentPolicy from '../../components/endowmentPolicy/EndowmentPolicy';

const LifeEndowmentPolicyPage = (props) => {
    return (
        <EndowmentPolicy {...props} />
    );
};

export default LifeEndowmentPolicyPage;
