import React, { useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { setTabState } from '../../redux/features/tab/tabSlice';

import ContactDetails from './ContactDetails';

const gridItemsStyle = `w-full h-16 p-3 flex gap-2 text-center rounded-md border border-gray-700 cursor-pointer`;

const MedicalHistory = (props) => {
    const { memberState } = props;
    const [showContactDetails, setShowContactDetails] = useState(false);
    const dispatch = useDispatch();
    const tabState = useSelector(state => state.tab.tabState);

    const previousTab = () => {
        dispatch(setTabState('AGE'));
    };

    const handleNext = () => {
        setShowContactDetails(true);
        dispatch(setTabState('CONTACT'));
    };
    return (
        <>
            {
                tabState === "MEDICAL" ? (
                    <>
                        <h3 className='text-3xl text-black text-center'>Medical History</h3>
                        <div className='w-full h-full p-3 mt-4 grid grid-cols-2 gap-5'>
                            <div className={`${gridItemsStyle}`}>
                                <input type='checkbox' className='' />
                                <label className='mt-1.5 text-lg text-gray-700 font-raleway'>Diabetes</label>
                            </div>
                            <div className={`${gridItemsStyle}`}>
                                <input type='checkbox' className='' />
                                <label className='mt-1.5 text-lg text-gray-700 font-raleway'>Blood Pressure</label>
                            </div>
                            <div className={`${gridItemsStyle}`}>
                                <input type='checkbox' className='' />
                                <label className='mt-1.5 text-lg text-gray-700 font-raleway'>Any Surgery</label>
                            </div>
                            <div className={`${gridItemsStyle}`}>
                                <input type='checkbox' className='' />
                                <label className='mt-1.5 text-lg text-gray-700 font-raleway'>Thyroid</label>
                            </div>
                            <div className={`${gridItemsStyle}`}>
                                <input type='checkbox' className='' />
                                <label className='mt-1.5 text-lg text-gray-700 font-raleway'>Asthma</label>
                            </div>
                            <div className={`${gridItemsStyle}`}>
                                <input type='checkbox' className='' />
                                <label className='mt-1.5 text-lg text-gray-700 font-raleway'>Other Disease</label>
                            </div>
                            <div className={`${gridItemsStyle}`}>
                                <input type='checkbox' className='' />
                                <label className='mt-1.5 text-lg text-gray-700 font-raleway'>None of these</label>
                            </div>
                        </div>
                        <div className='w-full mt-3 flex justify-between items-center'>
                            <button type="button" className='p-3 rounded-full border border-gray-500' onClick={previousTab}>
                                <MdKeyboardArrowLeft size={20} />
                            </button>
                            <button
                                type="button"
                                className="w-1/4 flex justify-center gap-2 text-lg p-2 shadow-md shadow-gray-600/90 rounded-xl
                             text-white bg-orange-500 hover:bg-orange-700 transition duration-300 capitalize"
                                onClick={() => handleNext()}>
                                continue
                                <MdKeyboardArrowRight size={20} className='mt-1' />
                            </button>
                        </div>
                    </>
                ) : (
                    <ContactDetails
                        {...props}
                        memberState={memberState}
                    />
                )
            }

        </>

    );
};

export default MedicalHistory;