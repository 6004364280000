import React from 'react';
import IciciCheckout from '../../../../../components/endowmentPolicy/icici/IciciCheckout';

const IciciCheckoutPage = (props) => {
    return (
        <div>
            <IciciCheckout {...props} />
        </div>
    );
};

export default IciciCheckoutPage;
