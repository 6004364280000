const policy_icon1 = '/images/policy_icons/United_India_Insurance.avif';
const policy_icon2 = '/images/policy_icons/National_Insurance.avif';
const policy_icon3 = '/images/policy_icons/Universal_Sompo.avif';
const policy_icon4 = '/images/policy_icons/Oriental_Insurance.avif';
const policy_icon5 = '/images/policy_icons/Bajaj_Allianz.webp';

const bikeArr = {
    thirdParty: [
        {
            id: 1,
            imgUrl: `${policy_icon1}`,
            premiumAmount: "1,366",
            planType: "Third Party",
        },
        {
            id: 2,
            imgUrl: `${policy_icon2}`,
            premiumAmount: "1,541",
            planType: "Third Party",
        },
        {
            id: 3,
            imgUrl: `${policy_icon3}`,
            premiumAmount: "1,463",
            planType: "Third Party",
        },
        {
            id: 4,
            imgUrl: `${policy_icon4}`,
            premiumAmount: "1,257",
            planType: "Third Party",
        },
    ],
    comprehensive: [
        {
            id: 1,
            imgUrl: `${policy_icon2}`,
            idvAmount: "29,464",
            premiumAmount: "1,431",
            planType: "Comprehensive",
        },
        {
            id: 2,
            imgUrl: `${policy_icon1}`,
            idvAmount: "21,440",
            premiumAmount: "1,485",
            planType: "Comprehensive",
        },
        {
            id: 3,
            imgUrl: `${policy_icon5}`,
            idvAmount: "31,591",
            premiumAmount: "1,694",
            planType: "Comprehensive",
        },
    ],
};

export default bikeArr;