import React, { useEffect, useState } from 'react';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import PlanDetails from '../../common/PlanDetails';
import { Switch } from '@headlessui/react';
import { API_POST_LIFE_INSURANCE_PROPOSAL } from '../../../config/ApiList';
import { ThreeCircles } from 'react-loader-spinner';

const inputLabelStyle = `text-sm p-1 font-medium absolute left-3 -top-3.5 bg-white`;
const inputFieldStyle = `w-full p-3 font-nunito rounded-md border border-gray-500/95 outline-none focus:border-blue-700`;

const IciciCheckout = (props) => {
    const { quotationId, planId } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [enabled, setEnabled] = useState(true);
    const [showTopButton, setShowTopButton] = useState(false);

    const appInfo = useSelector(state => state.iciciBolE28E34.appInfo);
    const proposerInfos = useSelector(state => state.iciciBolE28E34.proposerInfos);
    const lifeAssrdInfos = useSelector(state => state.iciciBolE28E34.lifeAssrdInfos);
    const healthDetails = useSelector(state => state.iciciBolE28E34.healthDetails);
    const productSelectionE28 = useSelector(state => state.iciciBolE28E34.productSelectionE28);
    const productSelectionE34 = useSelector(state => state.iciciBolE28E34.productSelectionE34);
    const nomineeInfos = useSelector(state => state.iciciBolE28E34.nomineeInfos);
    const eiaDetails = useSelector(state => state.iciciBolE28E34.eiaDetails);
    const advisorSalesDetails = useSelector(state => state.iciciBolE28E34.advisorSalesDetails);

    const goToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    const onScrollHandler = () => {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            setShowTopButton(true);
        } else {
            setShowTopButton(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', onScrollHandler);

        return () => {
            window.removeEventListener('onscroll', onScrollHandler);
        };
    });

    const handleBackToDetails = () => {
        navigate(`/life-insurance/endowment-policy/endowment-details/${quotationId}/${planId}`);
    };

    const saveProposal = () => {
        const healthData = healthDetails.map((item) => {
            const { question, ...rest } = item;
            return rest;
        });
        const postData = {
            ...appInfo,
            proposerInfos,
            lifeAssrdInfos,
            healthDetails: healthData,
            productSelection: planId === 'E28' ? productSelectionE28 : productSelectionE34,
            nomineeInfos,
            eiaDetails,
            advisorSalesDetails,
        };
        setIsLoading(true);
        setShowLoader(true);

        props.callRequest("POST", API_POST_LIFE_INSURANCE_PROPOSAL, false, postData).then(({ data }) => {
            const { URL } = data.data;
            setIsLoading(false);
            setShowLoader(false);
            window.location.replace(`${URL}`);
        }).catch((e) => {
            console.log(e);
        });
    };

    return (
        <div className='w-full min-h-[calc(100vh-6rem)]'>
            <div className='w-full h-10 bg-white border-b shadow-sm'>
                <div className='container w-full h-full px-16'>
                    <button className='p-2 flex gap-2 group' onClick={() => handleBackToDetails()}>
                        <BsArrowLeftCircle size={20} className='mt-0.5 group-hover:-translate-x-1 transition duration-300' />
                        <span className='font-nunito'>Back to details</span>
                    </button>
                </div>
            </div>
            <div className='w-full'>
                <div className='container w-full px-16 flex gap-2 mt-7'>
                    <div className='w-[70%] p-3 flex flex-col gap-y-6'>

                        {/* Proposer Info Section */}
                        <div className='w-full min-h-10 px-8 py-5 bg-white rounded-md shadow-sm shadow-gray-700'>
                            <div className='w-full text-lg font-semibold flex justify-between items-center'>
                                <span>1. Proposer Info</span>
                            </div>
                            <hr className='text-gray-500 mt-2' />
                            <div className='mt-3'>
                                {/* Personal Info Section */}
                                <div className='w-full py-4 grid grid-cols-2 gap-8'>
                                    <div>
                                        <div className='flex gap-7'>
                                            <div>Do you have Aadhaar card</div>
                                            <div className='flex gap-2'>
                                                <Switch
                                                    checked={enabled}
                                                    onChange={setEnabled}
                                                    className={`${enabled ? 'bg-orange-500' : 'bg-orange-700'}
          relative inline-flex h-[25px] w-[50px] shadow-sm shadow-gray-700 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
                                                >
                                                    <span className="sr-only">Use setting</span>

                                                    <span
                                                        aria-hidden="true"
                                                        className={`${enabled ? 'translate-x-7' : 'translate-x-0'}
            pointer-events-none inline-block h-[21px] w-[19px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                                    />
                                                </Switch>
                                                {
                                                    enabled ? (
                                                        <span className='text-orange-500'>Yes</span>
                                                    ) : (
                                                        <span className='text-orange-700'>No</span>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>First Name</div>
                                        <input
                                            type='text'
                                            name='frstNm'
                                            value={proposerInfos.frstNm}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Last Name</div>
                                        <input
                                            type='text'
                                            name='lstNm'
                                            value={proposerInfos.lstNm}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Date of Birth</div>
                                        <input
                                            type='text'
                                            name='dob'
                                            value={proposerInfos.dob}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Gender</div>
                                        <input
                                            type='text'
                                            name='gender'
                                            value={proposerInfos.gender}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Is Staff</div>
                                        <input
                                            type='text'
                                            name='isStaff'
                                            value={proposerInfos.isStaff}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Date of Commencement of Loan</div>
                                        <input
                                            type='text'
                                            name='dateOfCommencementOfLoan'
                                            value={proposerInfos.dateOfCommencementOfLoan}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Marital Status</div>
                                        <input
                                            type='text'
                                            name='mrtlSts'
                                            value={proposerInfos.mrtlSts}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Mobile No</div>
                                        <input
                                            type='text'
                                            name='mobNo'
                                            value={proposerInfos.mobNo}
                                            maxLength={10}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Relation</div>
                                        <input
                                            type='text'
                                            name='relationWithLa'
                                            value={proposerInfos.relationWithLa}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Share Portfolio</div>
                                        <input
                                            type='text'
                                            name='sharePortfolio'
                                            value={proposerInfos.sharePortfolio}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Father's Name</div>
                                        <input
                                            type='text'
                                            name='fathersName'
                                            value={proposerInfos.fathersName}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Mother's Name</div>
                                        <input
                                            type='text'
                                            name='mothersName'
                                            value={proposerInfos.mothersName}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Spouse Name</div>
                                        <input
                                            type='text'
                                            name='spouseName'
                                            value={proposerInfos.spouseName}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>CKYC No.</div>
                                        <input
                                            type='text'
                                            name='ckycNumber'
                                            value={proposerInfos.ckycNumber}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                </div>

                                {/* CKYC Section */}
                                <div className='w-full font-semibold my-3'>
                                    <span>CKYC Details</span>
                                </div>
                                <div className='w-full py-4 grid grid-cols-2 gap-8'>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>ID Proof</div>
                                        <input
                                            type='text'
                                            name='idPrf'
                                            value={proposerInfos.kycDoc.idPrf}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Address Proof</div>
                                        <input
                                            type='text'
                                            name='addPrf'
                                            value={proposerInfos.kycDoc.addPrf}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Age Proof</div>
                                        <input
                                            type='text'
                                            name='agePrf'
                                            value={proposerInfos.kycDoc.agePrf}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>IT Proof</div>
                                        <input
                                            type='text'
                                            name='itPrf'
                                            value={proposerInfos.kycDoc.itPrf}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Income Proof</div>
                                        <input
                                            type='text'
                                            name='incomePrf'
                                            value={proposerInfos.kycDoc.incomePrf}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>LDD ID Other Description</div>
                                        <input
                                            type='text'
                                            name='lddIdOthrDesc'
                                            value={proposerInfos.kycDoc.lddIdOthrDesc}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>LDD ID No.</div>
                                        <input
                                            type='text'
                                            name='lddIdNumber'
                                            value={proposerInfos.kycDoc.lddIdNumber}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>LDD ID Expiry Date</div>
                                        <input
                                            type='text'
                                            name='lddIdExpiryDate'
                                            value={proposerInfos.kycDoc.lddIdExpiryDate}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                </div>

                                {/* Communication Address Section */}
                                <div className='w-full font-semibold my-3'>
                                    <span>Communication Address</span>
                                </div>
                                <div className='w-full py-4 grid grid-cols-2 gap-8'>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Address Line 1</div>
                                        <input
                                            type='text'
                                            name='line1'
                                            value={proposerInfos.comunctnAddress.line1}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Address Line 2</div>
                                        <input
                                            type='text'
                                            name='line2'
                                            value={proposerInfos.comunctnAddress.line2}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Address Line 3</div>
                                        <input
                                            type='text'
                                            name='line3'
                                            value={proposerInfos.comunctnAddress.line3}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Pincode</div>
                                        <input
                                            type='text'
                                            name='pincode'
                                            value={proposerInfos.comunctnAddress.pincode}
                                            maxLength={6}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Landmark</div>
                                        <input
                                            type='text'
                                            name='landmark'
                                            value={proposerInfos.comunctnAddress.landmark}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>City</div>
                                        <input
                                            type='text'
                                            name='city'
                                            value={proposerInfos.comunctnAddress.city}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>State</div>
                                        <input
                                            type='text'
                                            name='state'
                                            value={proposerInfos.comunctnAddress.state}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Country</div>
                                        <input
                                            type='text'
                                            name='country'
                                            value={proposerInfos.comunctnAddress.country}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                </div>

                                {/* Permanent Address Section */}
                                <div className='w-full font-semibold my-3'>
                                    <span>Permanent Address</span>
                                </div>
                                <div className='w-full py-4 grid grid-cols-2 gap-8'>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Address Line 1</div>
                                        <input
                                            type='text'
                                            name='line1'
                                            value={proposerInfos.prmntAddress.line1}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Address Line 2</div>
                                        <input
                                            type='text'
                                            name='line2'
                                            value={proposerInfos.prmntAddress.line2}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Address Line 3</div>
                                        <input
                                            type='text'
                                            name='line3'
                                            value={proposerInfos.prmntAddress.line3}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Pincode</div>
                                        <input
                                            type='text'
                                            name='pincode'
                                            value={proposerInfos.prmntAddress.pincode}
                                            maxLength={6}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Landmark</div>
                                        <input
                                            type='text'
                                            name='landmark'
                                            value={proposerInfos.prmntAddress.landmark}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>City</div>
                                        <input
                                            type='text'
                                            name='city'
                                            value={proposerInfos.prmntAddress.city}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>State</div>
                                        <input
                                            type='text'
                                            name='state'
                                            value={proposerInfos.prmntAddress.state}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Country</div>
                                        <input
                                            type='text'
                                            name='country'
                                            value={proposerInfos.prmntAddress.country}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                </div>

                                {/* Education Section */}
                                <div className='w-full font-semibold my-3'>
                                    <span>Education</span>
                                </div>
                                <div className='w-full py-2 grid grid-cols-2 gap-8'>
                                    <div className='relative'>
                                        <input
                                            type='text'
                                            name='education'
                                            value={proposerInfos.education}
                                            className={`${inputFieldStyle} capitalize`}
                                        />
                                    </div>
                                </div>

                                {/* NRI Section */}
                                <div className='w-full font-semibold my-3'>
                                    <span>NRI Details</span>
                                </div>
                                <div className='w-full py-4 grid grid-cols-2 gap-8'>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Passport No.</div>
                                        <input
                                            type='text'
                                            name='passportNo'
                                            value={proposerInfos.nri.passportNo}
                                            placeholder='Enter passport no'
                                            className={`${inputFieldStyle} capitalize`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Date of Arriving India</div>
                                        <input
                                            type='text'
                                            name='dateOfArrivingIndia'
                                            value={proposerInfos.nri.dateOfArrivingIndia}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Date of Leaving India</div>
                                        <input
                                            type='text'
                                            name='dateOfLeavingIndia'
                                            value={proposerInfos.nri.dateOfLeavingIndia}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Duration of Stay (in month)</div>
                                        <input
                                            type='text'
                                            name='durationOfStayInMonth'
                                            value={proposerInfos.nri.durationOfStayInYear}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Duration of Stay (in year)</div>
                                        <input
                                            type='text'
                                            name='durationOfStayInYear'
                                            value={proposerInfos.nri.durationOfStayInYear}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Employer Name</div>
                                        <input
                                            type='text'
                                            name='nameOfEmployer'
                                            value={proposerInfos.nri.nameOfEmployer}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Travel Details</div>
                                        <input
                                            type='text'
                                            name='travelDetails'
                                            value={proposerInfos.nri.travelDetails}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Bank Type</div>
                                        <input
                                            type='text'
                                            name='bankType'
                                            value={proposerInfos.nri.bankType}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>NRI TIN issuing Country</div>
                                        <input
                                            type='text'
                                            name='nriTINIssuingCountry'
                                            value={proposerInfos.nri.nriTINIssuingCountry}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Country of Residence</div>
                                        <input
                                            type='text'
                                            name='countryOfResidence'
                                            value={proposerInfos.nri.countryOfResidence}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Current Residence</div>
                                        <input
                                            type='text'
                                            name='currentresidence'
                                            value={proposerInfos.nri.currentresidence}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>1st TIN No.</div>
                                        <input
                                            type='text'
                                            name='tinNum'
                                            value={proposerInfos.nri.tinNum}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>2nd TIN No.</div>
                                        <input
                                            type='text'
                                            name='tinNum2'
                                            value={proposerInfos.nri.tinNum2}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>3rd TIN No.</div>
                                        <input
                                            type='text'
                                            name='tinNum3'
                                            value={proposerInfos.nri.tinNum3}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>NRI Birth Country</div>
                                        <input
                                            type='text'
                                            name='nriBirthCountry'
                                            value={proposerInfos.nri.nriBirthCountry}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>NRI Birth Place</div>
                                        <input
                                            type='text'
                                            name='placeOfBirthNRI'
                                            value={proposerInfos.nri.placeOfBirthNRI}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Country of Residence 2</div>
                                        <input
                                            type='text'
                                            name='countryOfResidence2'
                                            value={proposerInfos.nri.countryOfResidence2}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Country of Residence 3</div>
                                        <input
                                            type='text'
                                            name='countryOfResidence3'
                                            value={proposerInfos.nri.countryOfResidence3}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Other Country of Residence</div>
                                        <input
                                            type='text'
                                            name='nriOtherResCountry'
                                            value={proposerInfos.nri.nriOtherResCountry}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Nationality</div>
                                        <input
                                            type='text'
                                            name='countryOfNationality'
                                            value={proposerInfos.nri.countryOfNationality}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Tax Resident US</div>
                                        <input
                                            type='text'
                                            name='nriTaxResidentUS'
                                            value={proposerInfos.nri.nriTaxResidentUS}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Purpose of Stay</div>
                                        <input
                                            type='text'
                                            name='purposeOfStay'
                                            value={proposerInfos.nri.purposeOfStay}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Travel in last 1 year</div>
                                        <input
                                            type='text'
                                            name='travelInLast1Year'
                                            value={proposerInfos.nri.travelInLast1Year}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Mode of Travel</div>
                                        <input
                                            type='text'
                                            name='modeOfTravel'
                                            value={proposerInfos.nri.modeOfTravel}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Nature of Business</div>
                                        <input
                                            type='text'
                                            name='natureOfBusiness'
                                            value={proposerInfos.nri.natureOfBusiness}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Intended Duration Stay (in year)</div>
                                        <input
                                            type='text'
                                            name='intentedDurationStayInYear'
                                            value={proposerInfos.nri.intentedDurationStayInYear}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Intended Duration Stay</div>
                                        <input
                                            type='text'
                                            name='intentedDurationStay'
                                            value={proposerInfos.nri.intentedDurationStay}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Safety Precaution</div>
                                        <input
                                            type='text'
                                            name='safetyPrecaution'
                                            value={proposerInfos.nri.safetyPrecaution}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                </div>

                                {/* Other Details */}
                                <div className='w-full font-semibold my-3'>
                                    <span>Other Details</span>
                                </div>
                                <div className='w-full py-4 grid grid-cols-2 gap-8'>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Occupation</div>
                                        <input
                                            type='text'
                                            name='occ'
                                            value={proposerInfos.occ}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Currently Investigated</div>
                                        <input
                                            type='text'
                                            name='currentlyInvestigated'
                                            value={proposerInfos.currentlyInvestigated}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>My Profession</div>
                                        <input
                                            type='text'
                                            name='myProf'
                                            value={proposerInfos.myProf}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Occupation Description</div>
                                        <input
                                            type='text'
                                            name='occDesc'
                                            value={proposerInfos.occDesc}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Inds Type</div>
                                        <input
                                            type='text'
                                            name='indsType'
                                            value={proposerInfos.indsType}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Inds Type Decsription</div>
                                        <input
                                            type='text'
                                            name='indsTypeDesc'
                                            value={proposerInfos.indsTypeDesc}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Name of Organisation</div>
                                        <input
                                            type='text'
                                            name='nameOfOrg'
                                            value={proposerInfos.nameOfOrg}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Objective</div>
                                        <input
                                            type='text'
                                            name='objective'
                                            value={proposerInfos.objective}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Annual Income</div>
                                        <input
                                            type='text'
                                            name='annIncme'
                                            value={proposerInfos.annIncme}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>PAN No.</div>
                                        <input
                                            type='text'
                                            name='panNo'
                                            value={proposerInfos.panNo}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Photo Submitted</div>
                                        <input
                                            type='text'
                                            name='photoSubmitted'
                                            value={proposerInfos.photoSubmitted}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Nationality</div>
                                        <input
                                            type='text'
                                            name='nationality'
                                            value={proposerInfos.nationality}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Email</div>
                                        <input
                                            type='text'
                                            name='email'
                                            value={proposerInfos.email}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Politically Exposed</div>
                                        <input
                                            type='text'
                                            name='pltclyExpsd'
                                            value={proposerInfos.pltclyExpsd}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>RST Status</div>
                                        <input
                                            type='text'
                                            name='rstSts'
                                            value={proposerInfos.rstSts}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Life Assured Info Section */}
                        <div className='w-full min-h-10 px-8 py-5 bg-white rounded-md shadow-sm shadow-gray-700'>
                            <div className='w-full text-lg font-semibold flex justify-between items-center'>
                                <span>2. Life Assured Info</span>
                            </div>
                            <hr className='text-gray-500 mt-2' />
                            <div className='mt-3'>
                                {/* Personal Info Section */}
                                <div className='w-full py-4 grid grid-cols-2 gap-8'>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>First Name</div>
                                        <input
                                            type='text'
                                            name='frstNm'
                                            value={lifeAssrdInfos.frstNm}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Last Name</div>
                                        <input
                                            type='text'
                                            name='lstNm'
                                            value={lifeAssrdInfos.lstNm}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Date of Birth</div>
                                        <input
                                            type='text'
                                            name='dob'
                                            value={lifeAssrdInfos.dob}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Gender</div>
                                        <input
                                            type='text'
                                            name='gender'
                                            value={lifeAssrdInfos.gender}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Marital Status</div>
                                        <input
                                            type='text'
                                            name='mrtlSts'
                                            value={lifeAssrdInfos.mrtlSts}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Mobile No</div>
                                        <input
                                            type='text'
                                            name='mobNo'
                                            value={lifeAssrdInfos.mobNo}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Email</div>
                                        <input
                                            type='text'
                                            name='email'
                                            value={lifeAssrdInfos.email}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Mobile No</div>
                                        <input
                                            type='text'
                                            name='mobNo'
                                            value={lifeAssrdInfos.mobNo}
                                            maxLength={10}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Pincode</div>
                                        <input
                                            type='text'
                                            name='pinCode'
                                            value={lifeAssrdInfos.pinCode}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Nationality</div>
                                        <input
                                            type='text'
                                            name='nationality'
                                            value={lifeAssrdInfos.nationality}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Aadhar Card No</div>
                                        <input
                                            type='text'
                                            name='aadharCardNo'
                                            value={lifeAssrdInfos.aadharCardNo}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Education</div>
                                        <input
                                            type='text'
                                            name='education'
                                            value={lifeAssrdInfos.education}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                </div>

                                {/* KYC Section */}
                                <div className='w-full font-semibold my-3'>
                                    <span>KYC Details</span>
                                </div>
                                <div className='w-full py-4 grid grid-cols-2 gap-8'>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>ID Proof</div>
                                        <input
                                            type='text'
                                            name='idPrf'
                                            value={lifeAssrdInfos.kycDoc.idPrf}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Address Proof</div>
                                        <input
                                            type='text'
                                            name='addPrf'
                                            value={lifeAssrdInfos.kycDoc.addPrf}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Age Proof</div>
                                        <input
                                            type='text'
                                            name='agePrf'
                                            value={lifeAssrdInfos.kycDoc.agePrf}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>IT Proof</div>
                                        <input
                                            type='text'
                                            name='itPrf'
                                            value={lifeAssrdInfos.kycDoc.itPrf}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Income Proof</div>
                                        <input
                                            type='text'
                                            name='incomePrf'
                                            value={lifeAssrdInfos.kycDoc.incomePrf}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>LDD ID Other Description</div>
                                        <input
                                            type='text'
                                            name='lddIdOthrDesc'
                                            value={lifeAssrdInfos.kycDoc.lddIdOthrDesc}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>LDD ID No.</div>
                                        <input
                                            type='text'
                                            name='lddIdNumber'
                                            value={lifeAssrdInfos.kycDoc.lddIdNumber}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>LDD ID Expiry Date</div>
                                        <input
                                            type='text'
                                            name='lddIdExpiryDate'
                                            value={lifeAssrdInfos.kycDoc.lddIdExpiryDate}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                </div>

                                {/* Communication Address Section */}
                                <div className='w-full font-semibold my-3'>
                                    <span>Communication Address</span>
                                </div>
                                <div className='w-full py-4 grid grid-cols-2 gap-8'>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Address Line 1</div>
                                        <input
                                            type='text'
                                            name='line1'
                                            value={lifeAssrdInfos.comunctnAddress.line1}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Address Line 2</div>
                                        <input
                                            type='text'
                                            name='line2'
                                            value={lifeAssrdInfos.comunctnAddress.line2}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Address Line 3</div>
                                        <input
                                            type='text'
                                            name='line3'
                                            value={lifeAssrdInfos.comunctnAddress.line3}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Pincode</div>
                                        <input
                                            type='text'
                                            name='pincode'
                                            value={lifeAssrdInfos.comunctnAddress.pincode}
                                            maxLength={6}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Landmark</div>
                                        <input
                                            type='text'
                                            name='landmark'
                                            value={lifeAssrdInfos.comunctnAddress.landmark}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>City</div>
                                        <input
                                            type='text'
                                            name='city'
                                            value={lifeAssrdInfos.comunctnAddress.city}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>State</div>
                                        <input
                                            type='text'
                                            name='state'
                                            value={lifeAssrdInfos.comunctnAddress.state}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Country</div>
                                        <input
                                            type='text'
                                            name='country'
                                            value={lifeAssrdInfos.comunctnAddress.country}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                </div>

                                {/* Permanent Address Section */}
                                <div className='w-full font-semibold my-3'>
                                    <span>Permanent Address</span>
                                </div>
                                <div className='w-full py-4 grid grid-cols-2 gap-8'>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Address Line 1</div>
                                        <input
                                            type='text'
                                            name='line1'
                                            value={lifeAssrdInfos.prmntAddress.line1}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Address Line 2</div>
                                        <input
                                            type='text'
                                            name='line2'
                                            value={lifeAssrdInfos.prmntAddress.line2}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Address Line 3</div>
                                        <input
                                            type='text'
                                            name='line3'
                                            value={lifeAssrdInfos.prmntAddress.line3}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Pincode</div>
                                        <input
                                            type='text'
                                            name='pincode'
                                            value={lifeAssrdInfos.prmntAddress.pincode}
                                            maxLength={6}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Landmark</div>
                                        <input
                                            type='text'
                                            name='landmark'
                                            value={lifeAssrdInfos.prmntAddress.landmark}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>City</div>
                                        <input
                                            type='text'
                                            name='city'
                                            value={lifeAssrdInfos.prmntAddress.city}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>State</div>
                                        <input
                                            type='text'
                                            name='state'
                                            value={lifeAssrdInfos.prmntAddress.state}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Country</div>
                                        <input
                                            type='text'
                                            name='country'
                                            value={lifeAssrdInfos.prmntAddress.country}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                </div>

                                {/* NRI Section */}
                                <div className='w-full font-semibold my-3'>
                                    <span>NRI Details</span>
                                </div>
                                <div className='w-full py-4 grid grid-cols-2 gap-8'>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Passport No.</div>
                                        <input
                                            type='text'
                                            name='passportNo'
                                            value={lifeAssrdInfos.nri.passportNo}
                                            className={`${inputFieldStyle} capitalize`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Date of Arriving India</div>
                                        <input
                                            type='text'
                                            name='dateOfArrivingIndia'
                                            value={lifeAssrdInfos.nri.dateOfArrivingIndia}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Date of Leaving India</div>
                                        <input
                                            type='text'
                                            name='dateOfLeavingIndia'
                                            value={lifeAssrdInfos.nri.dateOfLeavingIndia}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Duration of Stay (in month)</div>
                                        <input
                                            type='text'
                                            name='durationOfStayInMonth'
                                            value={lifeAssrdInfos.nri.durationOfStayInYear}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Duration of Stay (in year)</div>
                                        <input
                                            type='text'
                                            name='durationOfStayInYear'
                                            value={lifeAssrdInfos.nri.durationOfStayInYear}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Employer Name</div>
                                        <input
                                            type='text'
                                            name='nameOfEmployer'
                                            value={lifeAssrdInfos.nri.nameOfEmployer}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Travel Details</div>
                                        <input
                                            type='text'
                                            name='travelDetails'
                                            value={lifeAssrdInfos.nri.travelDetails}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Bank Type</div>
                                        <input
                                            type='text'
                                            name='bankType'
                                            value={lifeAssrdInfos.nri.bankType}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>NRI TIN issuing Country</div>
                                        <input
                                            type='text'
                                            name='nriTINIssuingCountry'
                                            value={lifeAssrdInfos.nri.nriTINIssuingCountry}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Country of Residence</div>
                                        <input
                                            type='text'
                                            name='countryOfResidence'
                                            value={lifeAssrdInfos.nri.countryOfResidence}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Current Residence</div>
                                        <input
                                            type='text'
                                            name='currentresidence'
                                            value={lifeAssrdInfos.nri.currentresidence}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>1st TIN No.</div>
                                        <input
                                            type='text'
                                            name='tinNum'
                                            value={lifeAssrdInfos.nri.tinNum}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>2nd TIN No.</div>
                                        <input
                                            type='text'
                                            name='tinNum2'
                                            value={lifeAssrdInfos.nri.tinNum2}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>3rd TIN No.</div>
                                        <input
                                            type='text'
                                            name='tinNum3'
                                            value={lifeAssrdInfos.nri.tinNum3}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>NRI Birth Country</div>
                                        <input
                                            type='text'
                                            name='nriBirthCountry'
                                            value={lifeAssrdInfos.nri.nriBirthCountry}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>NRI Birth Place</div>
                                        <input
                                            type='text'
                                            name='placeOfBirthNRI'
                                            value={lifeAssrdInfos.nri.placeOfBirthNRI}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Country of Residence 2</div>
                                        <input
                                            type='text'
                                            name='countryOfResidence2'
                                            value={lifeAssrdInfos.nri.countryOfResidence2}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Country of Residence 3</div>
                                        <input
                                            type='text'
                                            name='countryOfResidence3'
                                            value={lifeAssrdInfos.nri.countryOfResidence3}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Other Country of Residence</div>
                                        <input
                                            type='text'
                                            name='nriOtherResCountry'
                                            value={lifeAssrdInfos.nri.nriOtherResCountry}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Nationality</div>
                                        <input
                                            type='text'
                                            name='countryOfNationality'
                                            value={lifeAssrdInfos.nri.countryOfNationality}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Tax Resident US</div>
                                        <input
                                            type='text'
                                            name='nriTaxResidentUS'
                                            value={lifeAssrdInfos.nri.nriTaxResidentUS}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Purpose of Stay</div>
                                        <input
                                            type='text'
                                            name='purposeOfStay'
                                            value={lifeAssrdInfos.nri.purposeOfStay}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Travel in last 1 year</div>
                                        <input
                                            type='text'
                                            name='travelInLast1Year'
                                            value={lifeAssrdInfos.nri.travelInLast1Year}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Mode of Travel</div>
                                        <input
                                            type='text'
                                            name='modeOfTravel'
                                            value={lifeAssrdInfos.nri.modeOfTravel}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Nature of Business</div>
                                        <input
                                            type='text'
                                            name='natureOfBusiness'
                                            value={lifeAssrdInfos.nri.natureOfBusiness}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Intended Duration Stay (in year)</div>
                                        <input
                                            type='text'
                                            name='intentedDurationStayInYear'
                                            value={lifeAssrdInfos.nri.intentedDurationStayInYear}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Intended Duration Stay</div>
                                        <input
                                            type='text'
                                            name='intentedDurationStay'
                                            value={lifeAssrdInfos.nri.intentedDurationStay}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Safety Precaution</div>
                                        <input
                                            type='text'
                                            name='safetyPrecaution'
                                            value={lifeAssrdInfos.nri.safetyPrecaution}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                </div>

                                {/* Other Details */}
                                <div className='w-full font-semibold my-3'>
                                    <span>Other Details</span>
                                </div>
                                <div className='w-full py-4 grid grid-cols-2 gap-8'>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Designation</div>
                                        <input
                                            type='text'
                                            name='desig'
                                            value={lifeAssrdInfos.desig}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Occupation</div>
                                        <input
                                            type='text'
                                            name='occ'
                                            value={lifeAssrdInfos.occ}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Occupation Description</div>
                                        <input
                                            type='text'
                                            name='occDesc'
                                            value={lifeAssrdInfos.occDesc}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>My Profession</div>
                                        <input
                                            type='text'
                                            name='myProf'
                                            value={lifeAssrdInfos.myProf}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Name of Organisation</div>
                                        <input
                                            type='text'
                                            name='nameOfOrg'
                                            value={lifeAssrdInfos.nri.nameOfOrg}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>RST Status</div>
                                        <input
                                            type='text'
                                            name='rstSts'
                                            value={lifeAssrdInfos.rstSts}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Currently Investigated</div>
                                        <input
                                            type='text'
                                            name='currentlyInvestigated'
                                            value={lifeAssrdInfos.currentlyInvestigated}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Annual Income</div>
                                        <input
                                            type='text'
                                            name='annIncme'
                                            value={lifeAssrdInfos.annIncme}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Health Section */}
                        <div className='w-full min-h-10 px-8 py-5 bg-white rounded-md shadow-sm shadow-gray-700'>
                            <div className='w-full text-lg font-semibold flex justify-between items-center'>
                                <span>3. Health Section</span>
                            </div>
                            <hr className='text-gray-500 mt-2' />
                            <div className='mt-3'>
                                {
                                    healthDetails.map((item) => {
                                        return (
                                            <div key={item.code} className='p-2 flex flex-col'>
                                                <span>{!['HQ167', 'HQ257', 'HQ258', 'CHQ94', 'CHQ95', 'CHQ96'].includes(item.code) && item.question}</span>
                                                <span className='text-gray-500 text-sm'>{!['HQ167', 'HQ257', 'HQ258', 'CHQ94', 'CHQ95', 'CHQ96'].includes(item.code) && item.answer1}</span>
                                                <span className='text-gray-500 text-sm'>{!['HQ167', 'HQ257', 'HQ258', 'CHQ94', 'CHQ95', 'CHQ96'].includes(item.code) && item.answer2}</span>
                                                <span className='text-gray-500 text-sm'>{!['HQ167', 'HQ257', 'HQ258', 'CHQ94', 'CHQ95', 'CHQ96'].includes(item.code) && item.answer3}</span>
                                                <span className='text-gray-500 text-sm'>{!['HQ167', 'HQ257', 'HQ258', 'CHQ94', 'CHQ95', 'CHQ96'].includes(item.code) && item.answer4}</span>

                                                {
                                                    item.code === 'HQ167' && (
                                                        <>
                                                            <span>{item.question}</span>
                                                            <div className='w-full p-3 rounded-md bg-gray-200'>
                                                                <div className='flex justify-between'>
                                                                    <span>Hypertension/ High BP/ high cholesterol</span>
                                                                    <span className='text-gray-500 text-sm'>{item.answer1}</span>
                                                                </div>
                                                                <div className='flex justify-between'>
                                                                    <span>Chest Pain/ Heart Attack/ any other heart disease or problem</span>
                                                                    <span className='text-gray-500 text-sm'>{item.answer2}</span>
                                                                </div>
                                                                <div className='flex justify-between'>
                                                                    <span>Undergone angioplasty, bypass surgery, heart surgery</span>
                                                                    <span className='text-gray-500 text-sm'>{item.answer3}</span>
                                                                </div>
                                                                <div className='flex justify-between'>
                                                                    <span>Diabetes/ High Blood Sugar/ Sugar in Urine</span>
                                                                    <span className='text-gray-500 text-sm'>{item.answer4}</span>
                                                                </div>
                                                                <div className='flex justify-between'>
                                                                    <span>Asthma, Tuberculosis or any other respiratory disorder</span>
                                                                    <span className='text-gray-500 text-sm'>{item.answer5}</span>
                                                                </div>
                                                                <div className='flex justify-between'>
                                                                    <span>Nervous disorders/ stroke/ paralysis/ epilepsy</span>
                                                                    <span className='text-gray-500 text-sm'>{item.answer6}</span>
                                                                </div>
                                                                <div className='flex justify-between'>
                                                                    <span>Any GastroIntestinal disorders like Pancreatitis, Colitis etc.</span>
                                                                    <span className='text-gray-500 text-sm'>{item.answer7}</span>
                                                                </div>
                                                                <div className='flex justify-between'>
                                                                    <span>Liver disorders/ Jaundice/ Hepatitis B or C</span>
                                                                    <span className='text-gray-500 text-sm'>{item.answer8}</span>
                                                                </div>
                                                                <div className='flex justify-between'>
                                                                    <span>Genitourinary disorders related to kidney, prostate, urinary system</span>
                                                                    <span className='text-gray-500 text-sm'>{item.answer9}</span>
                                                                </div>
                                                                <div className='flex justify-between'>
                                                                    <span>Cancer, Tumor, Growth or Cyst of any Kind</span>
                                                                    <span className='text-gray-500 text-sm'>{item.answer10}</span>
                                                                </div>
                                                                <div className='flex justify-between'>
                                                                    <span>HIV infection AIDS or positive test for HIV</span>
                                                                    <span className='text-gray-500 text-sm'>{item.answer11}</span>
                                                                </div>
                                                                <div className='flex justify-between'>
                                                                    <span>Any blood disorders like Anaemeia, Thalassemia etc</span>
                                                                    <span className='text-gray-500 text-sm'>{item.answer12}</span>
                                                                </div>
                                                                <div className='flex justify-between'>
                                                                    <span>Psychiatric or mental disorders</span>
                                                                    <span className='text-gray-500 text-sm'>{item.answer13}</span>
                                                                </div>
                                                                <div className='flex justify-between'>
                                                                    <span>Any other disorder not mentioned above</span>
                                                                    <span className='text-gray-500 text-sm'>{item.answer14}</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }

                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>

                        {/* Product Selection Section */}
                        <div className='w-full min-h-10 px-8 py-5 bg-white rounded-md shadow-sm shadow-gray-700'>
                            <div className='w-full text-lg font-semibold flex justify-between items-center'>
                                <span>4. Product Selection</span>
                            </div>
                            <hr className='text-gray-500 mt-2' />
                            <div className='mt-3'>
                                {
                                    planId === "E28" ? (
                                        <>
                                            {/* For Product E28 */}
                                            <div className='w-full py-4 grid grid-cols-2 gap-8'>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Selected Option</div>
                                                    <input
                                                        type='text'
                                                        name='selectedOption'
                                                        value={productSelectionE28.selectedOption}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Special Date</div>
                                                    <input
                                                        type='text'
                                                        name='specialDate'
                                                        value={productSelectionE28.specialDate}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Modal Premium</div>
                                                    <input
                                                        type='text'
                                                        name='modalPremium'
                                                        value={productSelectionE28.modalPremium}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Annual Premium</div>
                                                    <input
                                                        type='text'
                                                        name='annualPremium'
                                                        value={productSelectionE28.annualPremium}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Sum Assured</div>
                                                    <input
                                                        type='text'
                                                        name='sumAssured'
                                                        value={productSelectionE28.sumAssured}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Total Premium</div>
                                                    <input
                                                        type='text'
                                                        name='totalPremium'
                                                        value={productSelectionE28.totalPremium}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Maturity Amount</div>
                                                    <input
                                                        type='text'
                                                        name='maturityAmnt'
                                                        value={productSelectionE28.maturityAmnt}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Reverse Calculation</div>
                                                    <input
                                                        type='text'
                                                        name='reverseCalculation'
                                                        value={productSelectionE28.reverseCalculation}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Premium Payment Term</div>
                                                    <input
                                                        type='text'
                                                        name='premiumPayingTerm'
                                                        value={productSelectionE28.premiumPayingTerm}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Pension Frequency</div>
                                                    <input
                                                        type='text'
                                                        name='pensionFrequency'
                                                        value={productSelectionE28.pensionFrequency}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Sum Assured Multiple</div>
                                                    <input
                                                        type='text'
                                                        name='saMultiple'
                                                        value={productSelectionE28.saMultiple}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Death Benefit Option</div>
                                                    <input
                                                        type='text'
                                                        name='deathBenefitOption'
                                                        value={productSelectionE28.deathBenefitOption}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Premium Paying Frequency</div>
                                                    <input
                                                        type='text'
                                                        name='premiumPayingFrequency'
                                                        value={productSelectionE28.premiumPayingFrequency}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Policy Term</div>
                                                    <input
                                                        type='text'
                                                        name='policyTerm'
                                                        value={productSelectionE28.policyTerm}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Payout Term</div>
                                                    <input
                                                        type='text'
                                                        name='payoutTerm'
                                                        value={productSelectionE28.payoutTerm}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Product Type</div>
                                                    <input
                                                        type='text'
                                                        name='productType'
                                                        value={productSelectionE28.productType}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Product Name</div>
                                                    <input
                                                        type='text'
                                                        name='productName'
                                                        value={productSelectionE28.productName}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Product Id</div>
                                                    <input
                                                        type='text'
                                                        name='productId'
                                                        value={productSelectionE28.productId}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Premium Payment Option</div>
                                                    <input
                                                        type='text'
                                                        name='premiumpaymentoption'
                                                        value={productSelectionE28.premiumpaymentoption}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Occupation</div>
                                                    <input
                                                        type='text'
                                                        name='Occupation'
                                                        value={productSelectionE28.Occupation}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>

                                                {/* Rider 1 */}
                                                <div className='w-full min-h-10 px-8 py-5 flex flex-col gap-5 bg-white rounded-md shadow-sm shadow-gray-700'>
                                                    <div className='w-full font-semibold my-3'>
                                                        <span>Rider 1</span>
                                                    </div>
                                                    <div className='relative'>
                                                        <div className={`${inputLabelStyle}`}>Rider Name</div>
                                                        <input
                                                            type='text'
                                                            name='name'
                                                            value={productSelectionE28.rider1.name}
                                                            className={`${inputFieldStyle}`}
                                                        />
                                                    </div>
                                                    <div className='relative'>
                                                        <div className={`${inputLabelStyle}`}>Sum Assured</div>
                                                        <input
                                                            type='text'
                                                            name='sumAssured'
                                                            value={productSelectionE28.rider1.sumAssured}
                                                            className={`${inputFieldStyle}`}
                                                        />
                                                    </div>
                                                    <div className='relative'>
                                                        <div className={`${inputLabelStyle}`}>Term</div>
                                                        <input
                                                            type='text'
                                                            name='term'
                                                            value={productSelectionE28.rider1.term}
                                                            className={`${inputFieldStyle}`}
                                                        />
                                                    </div>
                                                </div>

                                                {/* Rider 2 */}
                                                <div className='w-full min-h-10 px-8 py-5 flex flex-col gap-5 bg-white rounded-md shadow-sm shadow-gray-700'>
                                                    <div className='w-full font-semibold my-3'>
                                                        <span>Rider 2</span>
                                                    </div>
                                                    <div className='relative'>
                                                        <div className={`${inputLabelStyle}`}>Rider Name</div>
                                                        <input
                                                            type='text'
                                                            name='name'
                                                            value={productSelectionE28.rider2.name}
                                                            className={`${inputFieldStyle}`}
                                                        />
                                                    </div>
                                                    <div className='relative'>
                                                        <div className={`${inputLabelStyle}`}>Sum Assured</div>
                                                        <input
                                                            type='text'
                                                            name='sumAssured'
                                                            value={productSelectionE28.rider2.sumAssured}
                                                            className={`${inputFieldStyle}`}
                                                        />
                                                    </div>
                                                    <div className='relative'>
                                                        <div className={`${inputLabelStyle}`}>Term</div>
                                                        <input
                                                            type='text'
                                                            name='term'
                                                            value={productSelectionE28.rider2.term}
                                                            className={`${inputFieldStyle}`}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Is Kerala Cess</div>
                                                    <input
                                                        type='text'
                                                        name='isKeralaCess'
                                                        value={productSelectionE28.isKeralaCess}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {/* For Product E34 */}
                                            <div className='w-full py-4 grid grid-cols-2 gap-8'>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Selected Option</div>
                                                    <input
                                                        type='text'
                                                        name='selectedOption'
                                                        value={productSelectionE34.selectedOption}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Modal Premium</div>
                                                    <input
                                                        type='text'
                                                        name='modalPremium'
                                                        value={productSelectionE34.modalPremium}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Annual Premium</div>
                                                    <input
                                                        type='text'
                                                        name='annualPremium'
                                                        value={productSelectionE34.annualPremium}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Sum Assured</div>
                                                    <input
                                                        type='text'
                                                        name='sumAssured'
                                                        value={productSelectionE34.sumAssured}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Total Premium</div>
                                                    <input
                                                        type='text'
                                                        name='totalPremium'
                                                        value={productSelectionE34.totalPremium}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Maturity Amount</div>
                                                    <input
                                                        type='text'
                                                        name='maturityAmnt'
                                                        value={productSelectionE34.maturityAmnt}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Reverse Calculation</div>
                                                    <input
                                                        type='text'
                                                        name='reverseCalculation'
                                                        value={productSelectionE34.reverseCalculation}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Premium Payment Term</div>
                                                    <input
                                                        type='text'
                                                        name='premiumPayingTerm'
                                                        value={productSelectionE34.premiumPayingTerm}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Pension Frequency</div>
                                                    <input
                                                        type='text'
                                                        name='pensionFrequency'
                                                        value={productSelectionE34.pensionFrequency}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Sum Assured Multiple</div>
                                                    <input
                                                        type='text'
                                                        name='saMultiple'
                                                        value={productSelectionE34.saMultiple}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Death Benefit Option</div>
                                                    <input
                                                        type='text'
                                                        name='deathBenefitOption'
                                                        value={productSelectionE34.deathBenefitOption}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Premium Paying Frequency</div>
                                                    <input
                                                        type='text'
                                                        name='premiumPayingFrequency'
                                                        value={productSelectionE34.premiumPayingFrequency}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Policy Term</div>
                                                    <input
                                                        type='text'
                                                        name='policyTerm'
                                                        value={productSelectionE34.policyTerm}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Payout Term</div>
                                                    <input
                                                        type='text'
                                                        name='payoutTerm'
                                                        value={productSelectionE34.payoutTerm}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Product Type</div>
                                                    <input
                                                        type='text'
                                                        name='productType'
                                                        value={productSelectionE34.productType}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Product Name</div>
                                                    <input
                                                        type='text'
                                                        name='productName'
                                                        value={productSelectionE34.productName}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Product Id</div>
                                                    <input
                                                        type='text'
                                                        name='productId'
                                                        value={productSelectionE34.productId}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Premium Payment Option</div>
                                                    <input
                                                        type='text'
                                                        name='premiumpaymentoption'
                                                        value={productSelectionE34.premiumpaymentoption}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Is Kerala Cess</div>
                                                    <input
                                                        type='text'
                                                        name='isKeralaCess'
                                                        value={productSelectionE34.isKeralaCess}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Portfolio Strategy</div>
                                                    <input
                                                        type='text'
                                                        name='portfolioStrategy'
                                                        value={productSelectionE34.portfolioStrategy}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Retirement Age</div>
                                                    <input
                                                        type='text'
                                                        name='RetirementAge'
                                                        value={productSelectionE34.RetirementAge}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Spouse Death Benefit</div>
                                                    <input
                                                        type='text'
                                                        name='spouseDeathBenefit'
                                                        value={productSelectionE34.spouseDeathBenefit}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Spouse Opted</div>
                                                    <input
                                                        type='text'
                                                        name='spouseOpted'
                                                        value={productSelectionE34.spouseOpted}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Option</div>
                                                    <input
                                                        type='text'
                                                        name='Option'
                                                        value={productSelectionE34.Option}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Tobacco JL</div>
                                                    <input
                                                        type='text'
                                                        name='tobaccoJL'
                                                        value={productSelectionE34.tobaccoJL}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Home Loan</div>
                                                    <input
                                                        type='text'
                                                        name='homeLoan'
                                                        value={productSelectionE34.homeLoan}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Sales Channel</div>
                                                    <input
                                                        type='text'
                                                        name='salesChannel'
                                                        value={productSelectionE34.salesChannel}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Loan Tenure</div>
                                                    <input
                                                        type='text'
                                                        name='loanTenure'
                                                        value={productSelectionE34.loanTenure}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Cash Benefit Mode</div>
                                                    <input
                                                        type='text'
                                                        name='cashBenefitMode'
                                                        value={productSelectionE34.cashBenefitMode}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Mode of Income</div>
                                                    <input
                                                        type='text'
                                                        name='modeOfIncome'
                                                        value={productSelectionE34.modeOfIncome}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>CI Benefit</div>
                                                    <input
                                                        type='text'
                                                        name='CIBenefit'
                                                        value={productSelectionE34.CIBenefit}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Spouse CI Benefit</div>
                                                    <input
                                                        type='text'
                                                        name='spouseCIBenefit'
                                                        value={productSelectionE34.spouseCIBenefit}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Spouse Lumpsum Percentage</div>
                                                    <input
                                                        type='text'
                                                        name='spouseLumpsumPercentage'
                                                        value={productSelectionE34.spouseLumpsumPercentage}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Family Benefit</div>
                                                    <input
                                                        type='text'
                                                        name='familyBenefit'
                                                        value={productSelectionE34.familyBenefit}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                                <div className='relative'>
                                                    <div className={`${inputLabelStyle}`}>Loyalty Benefit</div>
                                                    <input
                                                        type='text'
                                                        name='loyaltyBenefit'
                                                        value={productSelectionE34.loyaltyBenefit}
                                                        className={`${inputFieldStyle}`}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>

                        {/* Nominee Info Section */}
                        <div className='w-full min-h-10 px-8 py-5 bg-white rounded-md shadow-sm shadow-gray-700'>
                            <div className='w-full text-lg font-semibold flex justify-between items-center'>
                                <span>5. Nominee Info</span>
                            </div>
                            <hr className='text-gray-500 mt-2' />
                            <div className='mt-3'>
                                <div className='w-full py-4 grid grid-cols-2 gap-8'>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>First Name</div>
                                        <input
                                            type='text'
                                            name='frstNm'
                                            value={nomineeInfos.frstNm}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Last Name</div>
                                        <input
                                            type='text'
                                            name='lstNm'
                                            value={nomineeInfos.lstNm}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Date of Birth</div>
                                        <input
                                            type='text'
                                            name='dob'
                                            value={nomineeInfos.dob}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Gender</div>
                                        <input
                                            type='text'
                                            name='gender'
                                            value={nomineeInfos.gender}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Relationship</div>
                                        <input
                                            type='text'
                                            name='relationship'
                                            value={nomineeInfos.relationship}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                </div>

                                {/* Apnte Details */}
                                <div className='w-full font-semibold my-3'>
                                    <span>Apnte Details</span>
                                </div>
                                <div className='w-full py-4 grid grid-cols-2 gap-8'>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>First Name</div>
                                        <input
                                            type='text'
                                            name='frstNm'
                                            value={nomineeInfos.apnteDtls.frstNm}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Last Name</div>
                                        <input
                                            type='text'
                                            name='lstNm'
                                            value={nomineeInfos.apnteDtls.lstNm}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Date of Birth</div>
                                        <input
                                            type='text'
                                            name='dob'
                                            value={nomineeInfos.apnteDtls.dob}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Gender</div>
                                        <input
                                            type='text'
                                            name='gender'
                                            value={nomineeInfos.apnteDtls.gender}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Relationship</div>
                                        <input
                                            type='text'
                                            name='relationship'
                                            value={nomineeInfos.apnteDtls.relationship}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* EIA Details Section */}
                        <div className='w-full min-h-10 px-8 py-5 bg-white rounded-md shadow-sm shadow-gray-700'>
                            <div className='w-full text-lg font-semibold flex justify-between items-center'>
                                <span>6. EIA Details</span>
                            </div>
                            <hr className='text-gray-500 mt-2' />
                            <div className='mt-3'>
                                <div className='w-full py-4 grid grid-cols-2 gap-8'>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Is EIA Opted</div>
                                        <input
                                            type='text'
                                            name='isEIAOpted'
                                            value={eiaDetails.isEIAOpted}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>EIA Insurance Repository</div>
                                        <input
                                            type='text'
                                            name='eiaInsuranceRepository'
                                            value={eiaDetails.eiaInsuranceRepository}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>EIA Account Number</div>
                                        <input
                                            type='text'
                                            name='EIAAccountNumber'
                                            value={eiaDetails.EIAAccountNumber}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Advisor Sales Section */}
                        <div className='w-full min-h-10 px-8 py-5 bg-white rounded-md shadow-sm shadow-gray-700'>
                            <div className='w-full text-lg font-semibold flex justify-between items-center'>
                                <span>7. Advisor Sales</span>
                            </div>
                            <hr className='text-gray-500 mt-2' />
                            <div className='mt-3'>
                                <div className='w-full py-4 grid grid-cols-2 gap-8'>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>channel Type</div>
                                        <input
                                            type='text'
                                            name='channelType'
                                            value={advisorSalesDetails.channelType}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Customer Bank Account No.</div>
                                        <input
                                            type='text'
                                            name='cusBankAccNo'
                                            value={advisorSalesDetails.cusBankAccNo}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Bank Name</div>
                                        <input
                                            type='text'
                                            name='bankName'
                                            value={advisorSalesDetails.bankName}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Need Risk Profile</div>
                                        <input
                                            type='text'
                                            name='needRiskProfile'
                                            value={advisorSalesDetails.needRiskProfile}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>CSR Lim Code</div>
                                        <input
                                            type='text'
                                            name='csrLimCode'
                                            value={advisorSalesDetails.csrLimCode}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Cafos Code</div>
                                        <input
                                            type='text'
                                            name='cafosCode'
                                            value={advisorSalesDetails.cafosCode}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Opp Id</div>
                                        <input
                                            type='text'
                                            name='oppId'
                                            value={advisorSalesDetails.oppId}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>FSC Code</div>
                                        <input
                                            type='text'
                                            name='fscCode'
                                            value={advisorSalesDetails.fscCode}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>SP Code</div>
                                        <input
                                            type='text'
                                            name='spCode'
                                            value={advisorSalesDetails.spCode}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Bank Branch Name</div>
                                        <input
                                            type='text'
                                            name='bankBrnch'
                                            value={advisorSalesDetails.bankBrnch}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Source</div>
                                        <input
                                            type='text'
                                            name='source'
                                            value={advisorSalesDetails.source}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>LAN No</div>
                                        <input
                                            type='text'
                                            name='lanNo'
                                            value={advisorSalesDetails.lanNo}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Selected Tab</div>
                                        <input
                                            type='text'
                                            name='selectedTab'
                                            value={advisorSalesDetails.selectedTab}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                    <div className='relative'>
                                        <div className={`${inputLabelStyle}`}>Sub Channel</div>
                                        <input
                                            type='text'
                                            name='subChannel'
                                            value={advisorSalesDetails.subChannel}
                                            className={`${inputFieldStyle}`}
                                        />
                                    </div>
                                </div>
                            </div>
                            <button type="button" className="w-1/5 inline-flex justify-center items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-orange-500 hover:bg-orange-700 transition ease-in-out duration-150" disabled={isLoading} onClick={() => saveProposal()}>
                                {
                                    isLoading ? (
                                        <>
                                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            Saving...
                                        </>
                                    ) : 'Save'
                                }
                            </button>
                        </div>
                    </div>
                    <div className='w-[30%] p-3 flex flex-col gap-y-6'>
                        <PlanDetails {...props} />
                    </div>
                </div>
            </div>
            {
                showTopButton && (
                    <button type="button" className="fixed bottom-5 z-10 animate-bounce right-5 " onClick={goToTop}>
                        <div className="group flex h-14 w-14 items-center justify-center rounded-full border border-transparent bg-orange-500 text-white transition duration-500 hover:bg-orange-700">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="h-6 w-6 transition"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                            </svg>
                        </div>
                    </button>
                )}
            {
                showLoader && (
                    <div className='fixed inset-0 z-[100] bg-black/70'>
                        <div className='w-full h-full flex justify-center items-center'>
                            <ThreeCircles
                                visible={showLoader}
                                height="50"
                                width="50"
                                outerCircleColor="#f97316"
                                middleCircleColor="transparent"
                                innerCircleColor="#1d4fd8"
                                ariaLabel="three-circles-loading"
                            />
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default IciciCheckout;
