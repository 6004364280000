import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { setTermState } from '../../redux/features/life/lifeSlice';
import { setTermTabState } from '../../redux/features/lifeTab/lifeTabSlice';
import SmokeDisclaimer from './SmokeDisclaimer';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

const radioContainerStyle = `p-3 flex border-b border-gray-300 relative cursor-pointer`;
const radioOuterStyle = `w-4 h-4 top-4 absolute rounded-full bg-white border border-blue-500 flex 
                        justify-center items-center`;
const radioInnerStyle = `w-2.5 h-2.5 rounded-full bg-blue-400`;
const nextButtonStyle = `w-3/5 ml-auto flex justify-center gap-2 text-base p-2 shadow-md shadow-gray-600/90 rounded-xl 
                        text-white bg-orange-500 hover:bg-orange-700 transition duration-300 lg:w-1/2 lg:text-lg`;

const EducationalQualification = (props) => {
    const dispatch = useDispatch();
    const termTabState = useSelector(state => state.lifeTab.termTabState);
    const termState = useSelector(state => state.life.termState);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (e) => {
        dispatch(setTermState({ ...termState, educationalQualification: e }));
    };

    const handlePrevious = () => {
        dispatch(setTermTabState('ANNUAL_INCOME'));
    };

    const validateForm = () => {
        const { educationalQualification } = termState;
        const errors = {};
        let isValid = true;

        if (!educationalQualification) {
            isValid = false;
            errors.educationalQualification = "Please choose your educational qualification";
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleNext = () => {
        if (!validateForm()) {
            return false;
        }
        dispatch(setTermTabState('SMOKE'));
    };

    return (
        <>
            {
                termTabState === 'EDUCATION' ? (
                    <div className='max-w-lg w-full h-full rounded-xl bg-white shadow-sm shadow-gray-700'>
                        <div className='w-full py-3 lg:py-10'>
                            <div className='w-full p-3 flex justify-center items-center lg:p-0'>
                                <h1 className='text-lg sm:text-2xl'>Select Educational Qualification</h1>
                            </div>
                            <div className='w-full mt-4 sm:px-3'>
                                <div className='w-3/4 mx-auto'>
                                    <RadioGroup value={termState.educationalQualification} onChange={handleChange} className="w-full flex flex-col gap-x-3 border border-gray-300">
                                        <RadioGroup.Option value="College graduate & above" className={`${radioContainerStyle}`}>
                                            {({ checked }) => (
                                                <>
                                                    <div className={`${radioOuterStyle}`}>
                                                        {
                                                            checked ? (
                                                                <div className={`${radioInnerStyle}`}></div>
                                                            ) : null
                                                        }
                                                    </div>
                                                    <span className='ml-6'>College graduate & above</span>
                                                </>
                                            )}
                                        </RadioGroup.Option>

                                        <RadioGroup.Option value="12th Pass" className="p-3 border-b border-gray-300 relative cursor-pointer">
                                            {({ checked }) => (
                                                <>
                                                    <div className={`${radioOuterStyle}`}>
                                                        {
                                                            checked ? (
                                                                <div className={`${radioInnerStyle}`}></div>
                                                            ) : null
                                                        }
                                                    </div>
                                                    <span className='ml-6'>12th Pass</span>
                                                </>
                                            )}
                                        </RadioGroup.Option>

                                        <RadioGroup.Option value="10th Pass & below" className="p-3 border-b border-gray-300 relative cursor-pointer">
                                            {({ checked }) => (
                                                <>
                                                    <div className={`${radioOuterStyle}`}>
                                                        {
                                                            checked ? (
                                                                <div className={`${radioInnerStyle}`}></div>
                                                            ) : null
                                                        }
                                                    </div>
                                                    <span className='ml-6'>10th Pass & below</span>
                                                </>
                                            )}
                                        </RadioGroup.Option>
                                    </RadioGroup>
                                </div>
                            </div>
                            <p className='ml-[26%] mt-2 text-sm text-red-500'>{!termState.educationalQualification && formErrors.educationalQualification}</p>
                            <div className='w-full px-2 mt-7 flex justify-center items-center lg:px-10'>
                                <div className='w-full flex gap-5 justify-between'>
                                    <div className='w-1/2'>
                                        <button type="button" className='p-3 rounded-full border border-gray-500' onClick={handlePrevious}>
                                            <MdKeyboardArrowLeft size={20} />
                                        </button>
                                    </div>

                                    <div className='w-full h-full relative sm:w-1/3 lg:w-1/2'>
                                        <div className='w-full hidden sm:block'>
                                            <button
                                                type="button"
                                                className={`${nextButtonStyle}`}
                                                onClick={() => handleNext()}
                                            >
                                                Next
                                                <MdKeyboardArrowRight size={20} className='mt-1' />
                                            </button>
                                        </div>
                                    </div>

                                    <button type="button" className='p-2.5 rounded-full border border-orange-500 float-right bg-orange-500 text-white sm:hidden lg:p-3' onClick={handlePrevious}>
                                        <MdKeyboardArrowRight size={20} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <SmokeDisclaimer {...props} />
                )
            }
        </>
    );
};

export default EducationalQualification;