import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { setEndowmentState } from '../../redux/features/life/lifeSlice';
import { setEndowmentTabState } from '../../redux/features/lifeTab/lifeTabSlice';
import EndowmentIncomeSelection from './EndowmentIncomeSelection';

const defaultStyle = `bg-transparent border-gray-500 text-gray-400`;
const selectedStyle = `bg-blue-600 border-transparent text-white`;
const buttonStyle = `text-base border p-2 rounded-md cursor-pointer lg:px-4 lg:py-3 lg:text-lg`;
const nextButtonStyle = `w-3/5 ml-auto flex justify-center gap-2 text-base p-2 shadow-md shadow-gray-600/90 rounded-xl 
                        text-white bg-orange-500 hover:bg-orange-700 transition duration-300 lg:w-1/2 lg:text-lg`;

const EndowmentOccupationType = (props) => {
    const dispatch = useDispatch();
    const endowmentTabState = useSelector(state => state.lifeTab.endowmentTabState);
    const endowmentState = useSelector(state => state.life.endowmentState);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (e) => {
        dispatch(setEndowmentState({ ...endowmentState, occupationType: e }));
    };

    const handlePrevious = () => {
        dispatch(setEndowmentTabState('BASIC'));
    };

    const validateForm = () => {
        const { occupationType } = endowmentState;
        const errors = {};
        let isValid = true;

        if (!occupationType) {
            isValid = false;
            errors.occupationType = "Please choose your occupation type";
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleNext = () => {
        if (!validateForm()) {
            return false;
        }
        dispatch(setEndowmentTabState('ANNUAL_INCOME'));
    };

    return (
        <>
            {
                endowmentTabState === 'OCCUPATION' ? (
                    <div className='max-w-lg w-full h-full mt-20 rounded-xl bg-white shadow-sm shadow-gray-700'>
                        <div className='w-full py-3 grid grid-cols-1 lg:py-10'>
                            <div className='w-full p-3 flex justify-center items-center lg:p-0'>
                                <h1 className='text-lg sm:text-2xl'>Choose your occupation Type</h1>
                            </div>
                            <div className='w-full p-1 mt-2 lg:p-5 lg:mt-5'>
                                <RadioGroup value={endowmentState.occupationType} onChange={handleChange} className="flex gap-4 justify-center items-center">
                                    <RadioGroup.Option value="Salaried">
                                        {({ checked }) => (
                                            <span className={`${checked ? selectedStyle : defaultStyle} ${buttonStyle}`}>Salaried</span>
                                        )}
                                    </RadioGroup.Option>
                                    <RadioGroup.Option value="Self Employed">
                                        {({ checked }) => (
                                            <span className={`${checked ? selectedStyle : defaultStyle} ${buttonStyle}`}>Self Employed</span>
                                        )}
                                    </RadioGroup.Option>
                                </RadioGroup>
                            </div>
                            <p className='ml-3 mt-3 text-sm text-red-500 lg:ml-[27%] lg:mt-0'>{!endowmentState.occupationType && formErrors.occupationType}</p>
                            <div className='w-full px-2 mt-4 flex justify-center items-center lg:px-10 lg:mt-7'>
                                <div className='w-full flex gap-5 justify-between'>
                                    <div className='w-1/2'>
                                        <button type="button" className='p-2.5 rounded-full border border-gray-500 lg:p-3' onClick={handlePrevious}>
                                            <MdKeyboardArrowLeft size={20} />
                                        </button>
                                    </div>

                                    <div className='w-full h-full relative sm:w-1/3 lg:w-1/2'>
                                        <div className='w-full hidden sm:block'>
                                            <button
                                                type="button"
                                                className={`${nextButtonStyle}`}
                                                onClick={() => handleNext()}
                                            >
                                                Next
                                                <MdKeyboardArrowRight size={20} className='mt-0.5 lg:mt-1' />
                                            </button>
                                        </div>

                                        <button type="button" className='p-2.5 rounded-full border border-orange-500 float-right bg-orange-500 text-white sm:hidden lg:p-3' onClick={() => handleNext()}>
                                            <MdKeyboardArrowRight size={20} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <EndowmentIncomeSelection {...props} />
                )
            }
        </>
    );
};

export default EndowmentOccupationType;