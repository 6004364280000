import React from 'react';
import TermInsuranceDetails from '../../../../components/termInsurance/termInsuranceDetails/TermInsuranceDetails';

const TermInsuranceDetailsPage = (props) => {
    return (
        <div>
            <TermInsuranceDetails {...props} />
        </div>
    );
};

export default TermInsuranceDetailsPage;