import { useSelector } from 'react-redux';
import policyArr from '../../static/policyData';

const PolicyDetails = () => {
    const memberState = useSelector(state => state.member.memberState);

    return (
        <div className='w-full min-h-[calc(100vh-6rem)]'>
            <div className='w-full h-full px-10 py-2 bg-white border-b border-gray-600'>
                <div className='px-10 flex flex-col'>
                    <span className='text-sm'>Showing <span className='font-semibold'>{policyArr.length}</span> plan(s) for</span>
                    {
                        memberState.map((item) => (
                            <div key={item.id}>
                                <span className='font-bold'>{item.name}({item.age})</span>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='w-full min-h-full px-10 py-2 mt-5'>
                {
                    policyArr.map((policy) => {
                        return (
                            <div key={policy.id} className='w-3/4 h-20 px-5 mb-5 mx-auto flex bg-[#fff4e7] rounded-md shadow-sm shadow-gray-700'>
                                <div className='w-1/4 h-full flex justify-center items-center'>
                                    <img src={policy.imgUrl} alt='' width={100} className='block' />
                                </div>
                                <div className='w-2/5 h-full flex flex-col justify-center items-center'>
                                    <span>{policy.name}</span>
                                    <small className='text-gray-500'>₹ {policy.annualAmount} anually</small>
                                </div>
                                <div className='w-[calc(100%-(25%+40%))] h-full flex justify-center items-center'>
                                    <button className='p-3 rounded-lg bg-orange-500 hover:bg-orange-600/95 transition duration-300'>₹ {policy.monthlyAmount}/month</button>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default PolicyDetails;