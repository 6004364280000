import React from 'react';
import { Route, Routes } from 'react-router-dom';

import HomePage from '../pages/home/HomePage';

//Car Section Routes
import CarDetailPage from '../pages/carDetailPages/CarDetailPage';

//Bike Section Routes
import BikeDetailPage from '../pages/bikeDetailPages/BikeDetailPage';

//Health Section Routes
import HealthPages from '../pages/healthPages/HealthPages';
import PolicyDetailPage from '../pages/policyDetailPages/PolicyDetailPage';

//Life Insurance Section Routes
import LifeTermInsurancePage from '../pages/lifeInsurancePages/LifeTermInsurancePage';

//Life Insurance ---> Term Insurance Routes
import TermInsurancePage from '../pages/lifeInsurancePages/termInsurancePages/TermInsurancePage';
import TermInsuranceDetailsPage from '../pages/lifeInsurancePages/termInsurancePages/termInsuranceDetailPages/TermInsuranceDetailsPage';

//Life Insurance ---> Term Insurance Routes ---> ICICI
import PersonalInfoPage from '../pages/lifeInsurancePages/termInsurancePages/iciciPages/ebiPages/personalInfoPages/PersonalInfoPage';

//Life Insurance ---> Endowment Policy Routes
import EndowmentPolicyPage from '../pages/lifeInsurancePages/endowmentPolicyPages/EndowmentPolicyPage';
import EndowmentPolicyDetailsPage from '../pages/lifeInsurancePages/endowmentPolicyPages/endowmentPolicyDetailPages/EndowmentPolicyDetailsPage';
import LifeEndowmentPolicyPage from '../pages/lifeInsurancePages/LifeEndowmentPolicyPage';
import IciciCheckoutPage from '../pages/lifeInsurancePages/endowmentPolicyPages/endowmentPolicyCheckoutPages/iciciPages/IciciCheckoutPage';

const Router = (props) => {

  return (
    <>
      <Routes>
        <Route path='*' element={<HomePage {...props} />} />

        {/* Car Section Routes */}
        <Route path='/car-details' element={<CarDetailPage {...props} />} />

        {/* Bike Section Routes */}
        <Route path='/bike-details' element={<BikeDetailPage {...props} />} />

        {/* Health Section Routes */}
        <Route path='/health/health-details' element={<HealthPages {...props} />} />
        <Route path='/policy-details' element={<PolicyDetailPage {...props} />} />

        {/* Life Insurance Section Routes */}
        <Route
          path={`/life-insurance/term-insurance`}
          element={<LifeTermInsurancePage {...props} />}
        />
        <Route
          path={`/life-insurance/endowment-policy`}
          element={<LifeEndowmentPolicyPage {...props} />}
        />

        {/* Life Insurance ---> Term Insurance Routes */}
        <Route path='/life-insurance/term-insurance/insurance-results/:quotationId' element={<TermInsurancePage {...props} />} />
        <Route path='/life-insurance/term-insurance/insurance-details/:quotationId/:planId' element={<TermInsuranceDetailsPage {...props} />} />
        <Route path='/life-insurance/term-insurance/insurance-pre-ebi/:quotationId/:planId' element={<PersonalInfoPage {...props} />} />

        {/* Life Insurance ---> Endowment Policy Routes */}
        <Route path='/life-insurance/endowment-policy/endowment-results/:quotationId' element={<EndowmentPolicyPage {...props} />} />
        <Route path='/life-insurance/endowment-policy/endowment-details/:quotationId/:planId' element={<EndowmentPolicyDetailsPage {...props} />} />
        <Route path='/life-insurance/endowment-policy/checkout/:quotationId/:planId' element={<IciciCheckoutPage {...props} />} />
      </Routes>
    </>
  );
};

export default Router;