import React from 'react'
import PolicyDetails from '../../components/policyDetails/PolicyDetails'

const PolicyDetailPage = (props) => {
    return (
        <div>
            <PolicyDetails {...props} />
        </div>
    )
}

export default PolicyDetailPage