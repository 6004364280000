import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const logo_icon = '/images/logo.png';

const navLinkStyle = `block mt-4 hover:text-orange-600 lg:inline-block lg:mt-0 font-semibold mr-4`;
const navBtnStyle = `inline-block text-lg px-4 py-3 shadow-md shadow-gray-600/90 leading-none rounded-xl 
                    text-white transition duration-300`;
const sideNavLinksStyle = `p-4 border-b border-gray-400/80 hover:text-orange-600`;
const sideNavBtnStyle = `w-full text-lg px-4 py-3 shadow-md shadow-gray-600/90 rounded-xl text-white transition duration-300`;
const svgBtnStyle = `fill-current h-6 w-6 hover:text-orange-600 cursor-pointer`;

const Navbar = (props) => {
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        if (window.innerWidth < 1024) {
            setShowMenu(!showMenu);
        } else {
            setShowMenu(false);
        }
    };

    return (
        <div className='w-full h-24'>
            <div className="container h-full">
                <nav className="w-11/12 h-full mx-auto flex items-center justify-between gap-3">

                    {/* Logo Section */}
                    <div className="flex items-center">
                        <Link to="/" className='cursor-pointer'>
                            <img src={logo_icon} alt='' width={150} height={75} />
                        </Link>
                    </div>

                    {/* Hamburger Icon */}
                    <div className="block lg:hidden">
                        <button className="flex items-center px-3 py-2 border rounded text-black border-transparent">
                            <svg
                                className={`${svgBtnStyle}`}
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => toggleMenu()}
                            >
                                <title>Menu</title>
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                            </svg>
                        </button>
                    </div>

                    {/* NavLinks & Buttons*/}
                    <div className='w-full hidden lg:flex'>
                        <div className="w-full flex items-center justify-center">
                            <div className="text-lg flex justify-center gap-x-6 text-black">
                                <a href="#products" className={`${navLinkStyle}`}>
                                    Products
                                </a>
                                <a href="#renewals" className={`${navLinkStyle}`}>
                                    Renewals
                                </a>
                                <a href="#claims" className={`${navLinkStyle}`}>
                                    Claims
                                </a>
                                <a href="#resources" className={`${navLinkStyle}`}>
                                    Resources
                                </a>
                            </div>
                        </div>
                        <div className="w-1/4 flex gap-5 rounded-xl" >
                            <button href="#" className={`${navBtnStyle} bg-orange-500 hover:bg-orange-700`}>Login</button>
                            <button href="#" className={`${navBtnStyle} bg-blue-700 hover:bg-blue-900`}>Register</button>
                        </div>
                    </div>

                    {/* Overlay */}
                    <div
                        className={`${showMenu ? "" : "hidden"} fixed inset-0 z-[51] bg-black/60 lg:hidden`}
                        onClick={() => toggleMenu()}
                    ></div>

                    {/* Mobile Menu */}
                    {
                        showMenu && (
                            <div className='max-w-sm w-80 h-full z-[52] bg-gradient-to-r from-[#fffdf8] via-[#fcf1cd] to-[#fdd7a2] absolute top-0 right-0 transition duration-300 lg:hidden'>
                                <div className="flex flex-col space-y-4">
                                    <div className='p-3'>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className={`${svgBtnStyle}`}
                                            onClick={() => toggleMenu()}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </div>
                                    <ul>
                                        <li className={`${sideNavLinksStyle}`}>
                                            <a href="#products">Products</a>
                                        </li>
                                        <li className={`${sideNavLinksStyle}`}>
                                            <a href="#renewals">Renewals</a>
                                        </li>
                                        <li className={`${sideNavLinksStyle}`}>
                                            <a href="#claims">Claims</a>
                                        </li>
                                        <li className={`${sideNavLinksStyle}`}>
                                            <a href="#resources">Resources</a>
                                        </li>
                                    </ul>

                                    <div className='flex flex-col gap-4 p-3'>
                                        <button href="#" className={`${sideNavBtnStyle} bg-orange-500 hover:bg-orange-700 `}>Login</button>
                                        <button href="#" className={`${sideNavBtnStyle} bg-blue-700 hover:bg-blue-900`}>Register</button>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </nav>
            </div>
        </div>
    );
};

export default Navbar;