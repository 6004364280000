export const API_BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_DOMAIN = process.env.REACT_APP_API_HOST;

//Life
export const API_POST_LIFE_INSURANCE_QUOTE = API_DOMAIN + 'api/checkLifeInsuQuote';
export const API_GET_LIFE_INSURANCE_QUOTE = API_DOMAIN + 'api/getLifeInsuQuote';
export const API_GET_LIFE_INSURANCE_PRODUCT_DETAIL = API_DOMAIN + 'api/getLifeInsuProductDetail';
export const API_POST_LIFE_INSURANCE_PROPOSAL = API_DOMAIN + 'api/postLifeInsuProposal';


