import React from 'react';
import PersonalInfo from '../../../../../../components/termInsurance/icici/ebi/personalInfo/PersonalInfo';

const PersonalInfoPage = (props) => {
    return (
        <div>
            <PersonalInfo {...props} />
        </div>
    );
};

export default PersonalInfoPage;
