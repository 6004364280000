import React, { useState } from 'react';
import { FaArrowRightLong } from 'react-icons/fa6';
import OccupationType from './OccupationType';
import { useDispatch, useSelector } from 'react-redux';
import { setTermState } from '../../redux/features/life/lifeSlice';
import { setTermTabState } from '../../redux/features/lifeTab/lifeTabSlice';

const term_insurance = '/images/term_insurance.png';

const inputWrapperStyle = `w-11/12 flex flex-col mx-auto relative mb-7 md: lg:w-3/5`;
const inputLabelStyle = `text-xs p-1 absolute left-3 -top-3 bg-white`;
const inputFieldStyle = `p-4 rounded-md border border-gray-500/95 outline-none focus:border-blue-700`;
const errorMessageStyle = `mt-1 text-md text-red-500`;
const btnStyle = `w-full flex justify-center gap-2 text-lg p-2 shadow-md shadow-gray-600/90 rounded-xl
                text-white bg-orange-500 hover:bg-orange-700 transition duration-300 capitalize`;

const TermInsurance = (props) => {
    const dispatch = useDispatch();
    const termTabState = useSelector(state => state.lifeTab.termTabState);
    const termState = useSelector(state => state.life.termState);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        dispatch(setTermState({ ...termState, [name]: value }));
    };

    const validateForm = () => {
        const { fullName, dob, email, mobile, gender, } = termState;
        const errors = {};
        let isValid = true;

        if (!fullName) {
            isValid = false;
            errors.fullName = "Please enter a valid name";
        }
        if (fullName && !fullName.split(' ')[1]) {
            isValid = false;
            errors.fullNameValid = "Please enter a full name";
        }

        if (!dob) {
            isValid = false;
            errors.dob = "Please enter a valid date";
        } else if (!props.compareDate(dob)) {
            isValid = false;
            errors.invalid_dob = "Age cannot be below 18 years";
        } else if (!props.checkDateOfBirth(dob)) {
            isValid = false;
            errors.limit_dob = "Birth date should be after January 1, 1947.";
        }

        if (!email || !props.validateEmail(email)) {
            isValid = false;
            errors.email = "Please enter a valid email address";
        }

        if (!mobile) {
            isValid = false;
            errors.mobile = "Please enter mobile number";
        }

        if (!gender) {
            isValid = false;
            errors.gender = "Please select gender";
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleNext = () => {
        if (!validateForm()) {
            return false;
        }
        dispatch(setTermTabState('OCCUPATION'));
    };

    return (
        <div className='w-full min-h-[calc(100vh-6rem)] px-8'>
            <div className='w-full h-full px-2 flex flex-col justify-center items-center sm:px-7 lg:flex-row lg:items-start'>
                <div className='w-full h-full lg:w-1/2'>
                    <img src={term_insurance} alt='' className='mx-auto object-cover' />
                </div>
                {
                    termTabState === 'BASIC' ? (
                        <div className='w-full h-full rounded-xl bg-white shadow-sm shadow-gray-700 sm:w-3/5 md:w-1/2 lg:w-1/2'>
                            <div className='py-10'>
                                <div className={`${inputWrapperStyle}`}>
                                    <div className={`${inputLabelStyle}`}>Your Fullname</div>
                                    <input
                                        type='text'
                                        name='fullName'
                                        value={termState.fullName || ''}
                                        onChange={handleChange}
                                        placeholder='Enter your full name'
                                        className={`${inputFieldStyle}`}
                                    />
                                    <small className={`${errorMessageStyle}`}>
                                        {
                                            !termState.fullName ? formErrors.fullName
                                                : termState.fullName && !termState.fullName.split(' ')[1]
                                                    ? formErrors.fullNameValid
                                                    : ''
                                        }
                                    </small>
                                </div>
                                <div className={`${inputWrapperStyle}`}>
                                    <div className={`${inputLabelStyle}`}>Date of Birth</div>
                                    <input
                                        type='date'
                                        name='dob'
                                        value={termState.dob || ''}
                                        onChange={handleChange}
                                        min="1947-01-01"
                                        className={`${inputFieldStyle}`}
                                    />
                                    <div className='flex gap-3'>
                                        {
                                            termState.dob ? (
                                                <small className='px-2 py-1 mt-1 rounded-md text-xs text-blue-700 bg-gray-300'>
                                                    {props.calculateAge(termState.dob) > 0 ? props.calculateAge(termState.dob) : "0"} yrs
                                                </small>
                                            ) : null
                                        }
                                        <small className={`${errorMessageStyle}`}>{!termState.dob ? formErrors.dob : !props.compareDate(termState.dob) ? formErrors.invalid_dob : !props.checkDateOfBirth(termState.dob) ? formErrors.limit_dob : ''}</small>
                                    </div>
                                </div>
                                <div className="w-11/12 flex flex-col gap-x-10 mx-auto relative mb-7 lg:w-3/5">
                                    <div className='w-full flex gap-x-8'>
                                        <div className='flex items-center'>
                                            <span>Gender</span>
                                        </div>
                                        <div className='flex itens-center gap-x-7'>
                                            <div className='flex place-items-center'>
                                                <input
                                                    type='radio'
                                                    name='gender'
                                                    value='Male'
                                                    checked={termState.gender === 'Male' ? true : false}
                                                    onChange={handleChange}
                                                    className='w-4 h-4'
                                                />
                                                <label className='ml-2'>Male</label>
                                            </div>
                                            <div className='flex place-items-center'>
                                                <input
                                                    type='radio'
                                                    name='gender'
                                                    value='Female'
                                                    checked={termState.gender === 'Female' ? true : false}
                                                    onChange={handleChange}
                                                    className='w-4 h-4'
                                                />
                                                <label className='ml-2'>Female</label>
                                            </div>
                                        </div>
                                    </div>
                                    <small className={`${errorMessageStyle}`}>{!termState.gender && formErrors.gender}</small>
                                </div>
                                <div className={`${inputWrapperStyle}`}>
                                    <div className={`${inputLabelStyle}`}>Email</div>
                                    <input
                                        type='text'
                                        name='email'
                                        value={termState.email || ''}
                                        onChange={handleChange}
                                        placeholder='Enter your email'
                                        className={`${inputFieldStyle}`}
                                    />
                                    <small className={`${errorMessageStyle}`}>{(!termState.email || !props.validateEmail(termState.email)) && formErrors.email}</small>
                                </div>
                                <div className={`${inputWrapperStyle}`}>
                                    <div className={`${inputLabelStyle}`}>Mobile No</div>
                                    <input
                                        type='text'
                                        name='mobile'
                                        value={termState.mobile || ''}
                                        onChange={handleChange}
                                        onKeyDown={props.handleKeyPress}
                                        placeholder='Enter mobile no'
                                        maxLength={10}
                                        className={`${inputFieldStyle}`}
                                    />
                                    <small className={`${errorMessageStyle}`}>{!termState.mobile && formErrors.mobile}</small>
                                </div>
                                <div className='w-11/12 mx-auto lg:w-3/5'>
                                    <button
                                        type='button'
                                        className={`${btnStyle}`}
                                        onClick={handleNext}
                                    >
                                        View Free Quotes
                                        <FaArrowRightLong size={20} className='mt-1' />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <OccupationType {...props} />
                    )
                }
            </div>
        </div>
    );
};

export default TermInsurance;