import React from 'react';

import TermInsurance from '../../components/termInsurance/TermInsurance';

const LifeTermInsurancePage = (props) => {
    return (
        <TermInsurance {...props} />
    );
};

export default LifeTermInsurancePage;