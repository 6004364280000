import React from 'react';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PlanDetails from '../../../../common/PlanDetails';

const PersonalInfo = (props) => {
    const { quotationId } = useParams();
    const navigate = useNavigate();
    const eBIResponse = useSelector(state => state.life.eBIResponse);

    const handleBackToDetails = () => {
        navigate(`/life-insurance/term-insurance/insurance-details/${quotationId}/${eBIResponse?.PremiumSummary?.ProductCode}`);
    };
    return (
        <div className='w-full min-h-[calc(100vh-6rem)]'>
            <div className='w-full h-10 bg-white border-b shadow-sm'>
                <div className='container w-full h-full px-16'>
                    <button className='p-2 flex gap-2 group' onClick={() => handleBackToDetails()}>
                        <BsArrowLeftCircle size={20} className='mt-0.5 group-hover:-translate-x-1 transition duration-300' />
                        <span className='font-nunito'>Back to details</span>
                    </button>
                </div>
            </div>
            <div className='w-full'>
                <div className='container w-full px-16 flex gap-2 mt-7'>
                    <div className='w-[70%] p-3 flex flex-col gap-y-6'>

                    </div>
                    <div className='w-[30%] p-3 flex flex-col gap-y-6'>
                        <PlanDetails {...props} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersonalInfo;
