/* eslint-disable react/prop-types */
import { useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { setTabState } from '../../redux/features/tab/tabSlice';

import MedicalHistory from './MedicalHistory';
import { setAge } from '../../redux/features/member/memberSlice';

const AgeSelection = (props) => {
    const dispatch = useDispatch();
    const tabState = useSelector(state => state.tab.tabState);
    const memberState = useSelector(state => state.member.memberState);
    const [selectedMember, setSelectedMember] = useState(memberState);
    const [formErrors, setFormErrors] = useState({});

    const handleAgeChange = (id, value) => {
        const temp_state = selectedMember.map((data) => {
            if (data.id === id) {
                return { ...data, age: value };
            }
            return data;
        });
        setSelectedMember(temp_state);
        dispatch(setAge(temp_state));
    };

    const validateAge = () => {
        const errors = {};
        let isValid = true;

        selectedMember.map((item) => {
            if (!item.age) {
                isValid = false;
                errors.age_err = "Please enter age";
            }

            if (item.age) {
                const temp_state = selectedMember.map((data) => {
                    if (data.id === item.id) {
                        return { ...data, status: true };
                    }
                    return data;
                });
                setSelectedMember(temp_state);
            }
        });

        setFormErrors(errors);
        return isValid;
    };

    const previousTab = () => {
        dispatch(setTabState('MEMBER'));
    };

    const handleNext = () => {
        if (!validateAge()) {
            return false;
        }
        dispatch(setTabState('MEDICAL'));
    };

    return (
        <>
            {
                tabState === 'AGE' ? (
                    <>
                        <h3 className='text-2xl text-black text-center'>Select age of selected member(s)</h3>
                        {
                            selectedMember.map((member) => {
                                return (
                                    <div key={member.id}>
                                        <div className='w-full h-full p-3 mt-3 flex justify-center gap-4' key={member.id}>
                                            <img
                                                src={member.imgUrl}
                                                alt={member.alt}
                                                width={member.width}
                                                height={member.height}
                                                className={member.className}
                                            />
                                            <div className='relative'>
                                                <div className='text-xs p-1 absolute left-3 -top-3 bg-white'>
                                                    {
                                                        member.name === "Self" ? "Your Age" : `${member.name}'s Age`
                                                    }
                                                </div>
                                                <input
                                                    type='text'
                                                    name="age"
                                                    min="0"
                                                    value={member.age || ''}
                                                    onKeyDown={props.handleKeyPress}
                                                    onChange={(e) => handleAgeChange(member.id, e.target.value)}
                                                    className='p-3 outline-none border-none rounded-lg'
                                                    style={{ boxShadow: '1px 1px 3px #dbdbdb inset, -1px -1px 3px #4d4d4d inset' }}
                                                />
                                            </div>
                                        </div>
                                        <p className='ml-[35.5%] text-md text-red-500'>{!member.age && formErrors.age_err}</p>
                                    </div>
                                );
                            })
                        }

                        <div className='w-full mt-3 flex justify-between items-center'>
                            <button type="button" className='p-3 rounded-full border border-gray-500' onClick={previousTab}>
                                <MdKeyboardArrowLeft size={20} />
                            </button>
                            <button
                                type="button"
                                className="w-1/4 flex justify-center gap-2 text-lg p-2 shadow-md shadow-gray-600/90 rounded-xl
                             text-white bg-orange-500 hover:bg-orange-700 transition duration-300 capitalize"
                                onClick={() => handleNext()}
                            >
                                continue
                                <MdKeyboardArrowRight size={20} className='mt-1' />
                            </button>
                        </div>
                    </>
                ) : (
                    <MedicalHistory
                        {...props}
                        memberState={memberState}
                    />
                )
            }

        </>

    );
};

export default AgeSelection;