/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { MdKeyboardArrowRight } from "react-icons/md";

import AgeSelection from './AgeSelection';
import { useDispatch, useSelector } from 'react-redux';
import { setTabState } from '../../redux/features/tab/tabSlice';
import { resetState, addMember, removeMember } from '../../redux/features/member/memberSlice';

const gridItemsOuterStyle = `w-full h-20 gap-x-5 relative rounded-lg cursor-pointer`;
const gridItemsInnerStyle = `w-full h-full rounded-lg border-2 border-gray-200 flex justify-center items-center gap-4 transition duration-300`;

const male_icon = '/images/male-icon.png';
const spouse_icon = '/images/spouse-icon.png';
const son_icon = '/images/son-icon.png';
const daughter_icon = '/images/daughter-icon.png';

const MemberSelection = (props) => {
    const initialState = [
        {
            id: 1,
            name: "Self",
            imgUrl: `${male_icon}`,
            alt: "self-icon",
            className: "bg-[#dff6f9] rounded-full",
            width: 50,
            height: 50,
            isSet: false,
            age: "",
            status: false,
        },
        {
            id: 2,
            name: "Spouse",
            imgUrl: `${spouse_icon}`,
            alt: "spouse-icon",
            className: "bg-[#dff6f9] rounded-full",
            width: 50,
            height: 50,
            isSet: false,
            age: "",
            status: false,
        },
        {
            id: 3,
            name: "Son",
            imgUrl: `${son_icon}`,
            alt: "son-icon",
            className: "bg-[#dff6f9] rounded-full",
            width: 50,
            height: 50,
            isSet: false,
            age: "",
            status: false,
        },
        {
            id: 4,
            name: "Daughter",
            imgUrl: `${daughter_icon}`,
            alt: "daughter-icon",
            className: "bg-[#dff6f9] rounded-full",
            width: 50,
            height: 50,
            isSet: false,
            age: "",
            status: false,
        },
    ];
    const dispatch = useDispatch();
    const tabState = useSelector(state => state.tab.tabState);
    const memberState = useSelector(state => state.member.memberState);
    const [selectedMember, setSelectedMember] = useState(initialState);
    const [isSelected, setIsSelected] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        setSelectedMember(initialState);
        dispatch(resetState());
    }, []);

    const handleMemberSelection = (e, item) => {
        let checked = e.target.checked;
        if (checked) {
            item.isSet = true;
            const temp_state = selectedMember.map((member) => {
                if (member.id === item.id) {
                    return {
                        ...member, isSet: true
                    };
                }
                return member;
            });
            setSelectedMember(temp_state);
            dispatch(addMember(item));
            setIsSelected(checked);
        } else {
            const removeObj = { ...item, isSet: false };
            const temp_state = selectedMember.map((member) => {
                if (member.id === removeObj.id) {
                    return {
                        ...member, isSet: false
                    };
                }
                return member;
            });
            setSelectedMember(temp_state);
            dispatch(removeMember(removeObj));

            const not_checked = temp_state.every((item) => !item.isSet);
            setIsSelected(not_checked ? false : true);
        }
    };

    const validateSelection = () => {
        const errors = {};
        let isValid = true;

        if (!isSelected) {
            isValid = false;
            errors.member_err = "Please select at least one member";
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleNext = () => {
        if (!validateSelection()) {
            return false;
        }
        dispatch(setTabState('AGE'));
    };

    return (
        <>
            {
                tabState === 'MEMBER' ? (
                    <>
                        <h3 className='text-2xl text-black text-center'>Select members you want to insure</h3>
                        <div className='w-full h-full p-3 mt-4 grid grid-cols-3 gap-5'>
                            {
                                selectedMember.map((member) => {
                                    return (
                                        <div key={member.id} className={`${gridItemsOuterStyle}`}>
                                            <input
                                                type='checkbox'
                                                id={member.id}
                                                className='peer hidden absolute'
                                                checked={member.isSet}
                                                onChange={(e) => handleMemberSelection(e, member)}
                                            />
                                            <label htmlFor={member.id} className={`${gridItemsInnerStyle} ${member.isSet && "peer-checked:border-blue-500"}`}>
                                                <img
                                                    src={member.imgUrl}
                                                    alt={member.alt}
                                                    width={member.width}
                                                    height={member.height}
                                                    className={member.className}
                                                />
                                                {member.name}
                                            </label>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <small className='ml-3 text-md text-red-500'>{!isSelected && formErrors.member_err}</small>
                        <div className='w-full mt-3 px-5 flex justify-center items-center'>
                            <button
                                type="button"
                                className="w-1/4 flex justify-center gap-2 text-lg p-2 shadow-md shadow-gray-600/90 rounded-xl
                             text-white bg-orange-500 hover:bg-orange-700 transition duration-300 capitalize"
                                onClick={() => handleNext()}
                            >
                                continue
                                <MdKeyboardArrowRight size={20} className='mt-1' />
                            </button>
                        </div>
                    </>
                ) : (
                    <AgeSelection
                        {...props}
                        memberState={memberState}
                    />
                )
            }
        </>
    );
};
export default MemberSelection;