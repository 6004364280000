import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CarTab = () => {
    const [value, setValue] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        setValue(e.target.value);
        if (e.target.value !== '') {
            setError('');
        }
    };

    const handleBlur = () => {
        if (value === '') {
            setError("Please enter your car registration number");
        }
        else {
            setError('');
        }
    };

    const handleClick = (e) => {
        if (value === 'UK18D1111') {
            navigate("/car-details");
        }
        else {
            setError("Please provide correct registration number");
        }
    };
    return (
        <div className='w-full rounded-md p-6'>
            <div className='w-full'>
                <div className='p-3'>
                    <h1 className='font-bold text-lg text-gray-700'>Your Car Registration Number</h1>
                </div>
                <div className='p-3 -mt-3'>
                    <input
                        type='text'
                        placeholder="AB00XY1234"
                        maxLength={10}
                        className='w-full p-4 rounded-md border border-gray-500/95 outline-none focus:border-blue-700 uppercase'
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                </div>
                {error && <p className='px-4 text-sm text-red-500'>{error}</p>}
                <div className='p-3 flex justify-center items-center'>
                    <button
                        className='px-6 py-2 font-bold rounded-md shadow-md shadow-gray-500/90 text-white
                     transition duration-300 bg-orange-500/95 hover:bg-orange-700/95'
                        onClick={handleClick}
                    >
                        Get Price
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CarTab;