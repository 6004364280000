import React from 'react';
import EndowmentPolicyQuotes from '../../../components/endowmentPolicy/endowmentPolicyQuotes/EndowmentPolicyQuotes';

const EndowmentPolicyPage = (props) => {
    return (
        <div>
            <EndowmentPolicyQuotes {...props} />
        </div>
    );
};

export default EndowmentPolicyPage;
