import React, { useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { setEndowmentState } from '../../redux/features/life/lifeSlice';
import { setEndowmentTabState } from '../../redux/features/lifeTab/lifeTabSlice';
import { RadioGroup } from '@headlessui/react';

import EndowmentEducationalQualification from './EndowmentEducationalQualification';

const radioContainerStyle = `p-3 flex border-b border-gray-300 relative cursor-pointer`;
const radioOuterStyle = `w-4 h-4 top-4 absolute rounded-full bg-white border border-blue-500 flex 
                        justify-center items-center`;
const radioInnerStyle = `w-2.5 h-2.5 rounded-full bg-blue-400`;
const nextButtonStyle = `w-3/5 ml-auto flex justify-center gap-2 text-base p-2 shadow-md shadow-gray-600/90 rounded-xl 
                        text-white bg-orange-500 hover:bg-orange-700 transition duration-300 lg:w-1/2 lg:text-lg`;

const IncomeSelection = (props) => {
    const dispatch = useDispatch();
    const endowmentTabState = useSelector(state => state.lifeTab.endowmentTabState);
    const endowmentState = useSelector(state => state.life.endowmentState);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (e) => {
        dispatch(setEndowmentState({ ...endowmentState, incomeSelection: e }));
    };

    const handlePrevious = () => {
        dispatch(setEndowmentTabState('OCCUPATION'));
    };

    const validateForm = () => {
        const { incomeSelection } = endowmentState;
        const errors = {};
        let isValid = true;

        if (!incomeSelection) {
            isValid = false;
            errors.incomeSelection = "Please choose your income";
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleNext = () => {
        if (!validateForm()) {
            return false;
        }
        dispatch(setEndowmentTabState('EDUCATION'));
    };

    return (
        <>
            {
                endowmentTabState === 'ANNUAL_INCOME' ? (
                    <div className='max-w-lg w-full h-full rounded-xl bg-white shadow-sm shadow-gray-700 lg:max-w-[30rem]'>
                        <div className='w-full py-3 lg:py-10'>
                            <div className='w-full p-3 flex justify-center items-center lg:p-0'>
                                <h1 className='text-lg sm:text-2xl'>Select your annual income</h1>
                            </div>
                            <div className='w-full px-1 mt-4 sm:px-3'>
                                <div className='w-3/4 mx-auto lg:w-1/2'>
                                    <RadioGroup value={endowmentState.incomeSelection} onChange={handleChange} className="w-full flex flex-col gap-x-3 border border-gray-300">
                                        <RadioGroup.Option value="Less than 2 Lac" className={`${radioContainerStyle}`}>
                                            {({ checked }) => (
                                                <>
                                                    <div className={`${radioOuterStyle}`}>
                                                        {
                                                            checked ? (
                                                                <div className={`${radioInnerStyle}`}></div>
                                                            ) : null
                                                        }
                                                    </div>
                                                    <span className='ml-6'>Less than 2 Lac</span>
                                                </>
                                            )}
                                        </RadioGroup.Option>

                                        <RadioGroup.Option value="2 Lac - 4.9 Lac" className={`${radioContainerStyle}`}>
                                            {({ checked }) => (
                                                <>
                                                    <div className={`${radioOuterStyle}`}>
                                                        {
                                                            checked ? (
                                                                <div className={`${radioInnerStyle}`}></div>
                                                            ) : null
                                                        }
                                                    </div>
                                                    <span className='ml-6'>2 Lac - 4.9 Lac</span>
                                                </>
                                            )}
                                        </RadioGroup.Option>

                                        <RadioGroup.Option value="5 Lac - 9.9 Lac" className={`${radioContainerStyle}`}>
                                            {({ checked }) => (
                                                <>
                                                    <div className={`${radioOuterStyle}`}>
                                                        {
                                                            checked ? (
                                                                <div className={`${radioInnerStyle}`}></div>
                                                            ) : null
                                                        }
                                                    </div>
                                                    <span className='ml-6'>5 Lac - 9.9 Lac</span>
                                                </>
                                            )}
                                        </RadioGroup.Option>

                                        <RadioGroup.Option value="10 Lac - 14.9 Lac" className={`${radioContainerStyle}`}>
                                            {({ checked }) => (
                                                <>
                                                    <div className={`${radioOuterStyle}`}>
                                                        {
                                                            checked ? (
                                                                <div className={`${radioInnerStyle}`}></div>
                                                            ) : null
                                                        }
                                                    </div>
                                                    <span className='ml-6'>10 Lac - 14.9 Lac</span>
                                                </>
                                            )}
                                        </RadioGroup.Option>

                                        <RadioGroup.Option value="15 Lac - 24.9 Lac" className={`${radioContainerStyle}`}>
                                            {({ checked }) => (
                                                <>
                                                    <div className={`${radioOuterStyle}`}>
                                                        {
                                                            checked ? (
                                                                <div className={`${radioInnerStyle}`}></div>
                                                            ) : null
                                                        }
                                                    </div>
                                                    <span className='ml-6'>15 Lac - 24.9 Lac</span>
                                                </>
                                            )}
                                        </RadioGroup.Option >

                                        <RadioGroup.Option value="More than 25 Lac" className={`${radioContainerStyle}`}>
                                            {({ checked }) => (
                                                <>
                                                    <div className={`${radioOuterStyle}`}>
                                                        {
                                                            checked ? (
                                                                <div className={`${radioInnerStyle}`}></div>
                                                            ) : null
                                                        }
                                                    </div>
                                                    <span className='ml-6'>More than 25 Lac</span>
                                                </>
                                            )}
                                        </RadioGroup.Option>
                                    </RadioGroup>
                                </div>
                            </div>
                            <p className='ml-[26%] mt-2 text-sm text-red-500'>{!endowmentState.incomeSelection && formErrors.incomeSelection}</p>
                            <div className='w-full px-2 mt-4 flex justify-center items-center lg:px-10'>
                                <div className='w-full flex gap-5 justify-between'>
                                    <div className='w-1/2'>
                                        <button type="button" className='p-3 rounded-full border border-gray-500' onClick={handlePrevious}>
                                            <MdKeyboardArrowLeft size={20} />
                                        </button>
                                    </div>

                                    <div className='w-full h-full relative sm:w-1/3 lg:w-1/2'>
                                        <div className='w-full hidden sm:block'>
                                            <button
                                                type="button"
                                                className={`${nextButtonStyle}`}
                                                onClick={() => handleNext()}
                                            >
                                                Next
                                                <MdKeyboardArrowRight size={20} className='mt-1' />
                                            </button>
                                        </div>
                                    </div>

                                    <button type="button" className='p-2.5 rounded-full border border-orange-500 float-right bg-orange-500 text-white sm:hidden lg:p-3' onClick={() => handleNext()}>
                                        <MdKeyboardArrowRight size={20} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <EndowmentEducationalQualification {...props} />
                )
            }
        </>
    );
};

export default IncomeSelection;
