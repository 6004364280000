import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { FaArrowRightLong } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetTermState, resetEndowmentState, setTypeState } from '../../redux/features/life/lifeSlice';
import { setEndowmentTabState, setTermTabState } from '../../redux/features/lifeTab/lifeTabSlice';

const LifeTab = () => {
    const dispatch = useDispatch();
    const typeState = useSelector(state => state.life.typeState);
    const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();

    const handleChange = (e) => {
        dispatch(setTypeState(e));
    };

    const validate = () => {
        const errors = {};
        let isValid = true;

        if (!typeState) {
            isValid = false;
            errors.type = "Please select insurance type";
        }
        if (!['Term', 'Endowment'].includes(typeState)) {
            isValid = false;
            errors.type_err = "Please select Term or Endowment";
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleClick = () => {
        if (!validate()) {
            return false;
        }
        if (typeState === 'Term') {
            navigate('/life-insurance/term-insurance');
            dispatch(resetEndowmentState());
            dispatch(setTermTabState('BASIC'));
        } else if (typeState === 'Endowment') {
            navigate('/life-insurance/endowment-policy');
            dispatch(resetTermState());
            dispatch(setEndowmentTabState('BASIC'));
        }
    };

    return (
        <div className='w-full rounded-md p-6'>
            <span className='font-bold text-lg text-gray-700'>Select Insurance Type</span>
            <RadioGroup value={typeState} onChange={handleChange} className="mt-3 grid grid-cols-2 gap-4">
                <RadioGroup.Option value="Term" className="w-full h-20 px-2 shadow-sm shadow-gray-500/90 cursor-pointer hover:shadow-md hover:shadow-gray-500/90 bg-[#f9feff]">
                    {({ checked }) => (
                        <div className='relative flex flex-col justify-center items-center bg-transparent'>
                            {
                                checked ? (
                                    <div className='w-6 h-6 absolute -right-2 top-0 rounded-full bg-white'>
                                        <div className='w-5 h-5 absolute right-0.5 top-0.5 rounded-full box-border bg-blue-700'>
                                            <FaCheck size={13} className='absolute left-[15%] top-[20%] text-white' />
                                        </div>
                                    </div>
                                ) : null
                            }
                            <img src='./images/term_insurance_icon2.png' alt='term_insurance_icon2' width={50} height={50} className='my-2 bg-[#dff6f9] rounded-full' />
                            <span className="-mt-2.5 font-semibold">Term</span>
                        </div>
                    )}
                </RadioGroup.Option>
                <RadioGroup.Option value="Endowment" className="w-full h-20 px-2 shadow-sm shadow-gray-500/90 cursor-pointer hover:shadow-md hover:shadow-gray-500/90 bg-[#f9feff]">
                    {({ checked }) => (
                        <div className='relative flex flex-col justify-center items-center bg-transparent'>
                            {
                                checked ? (
                                    <div className='w-6 h-6 absolute -right-2 top-0 rounded-full bg-white'>
                                        <div className='w-5 h-5 absolute right-0.5 top-0.5 rounded-full box-border bg-blue-700'>
                                            <FaCheck size={13} className='absolute left-[15%] top-[20%] text-white' />
                                        </div>
                                    </div>
                                ) : null
                            }
                            <img src='./images/endowment_icon.webp' alt='endowment_icon' width={50} height={50} className='my-2 bg-[#dff6f9] rounded-full' />
                            <span className="-mt-2.5 font-semibold">Endowment</span>
                        </div>
                    )}
                </RadioGroup.Option>
                <RadioGroup.Option value="Child" className="w-full h-20 px-2 shadow-sm shadow-gray-500/90 cursor-pointer hover:shadow-md hover:shadow-gray-500/90 bg-[#f9feff]">
                    {({ checked }) => (
                        <div className='relative flex flex-col justify-center items-center bg-transparent'>
                            {
                                checked ? (
                                    <div className='w-6 h-6 absolute -right-2 top-0 rounded-full bg-white'>
                                        <div className='w-5 h-5 absolute right-0.5 top-0.5 rounded-full box-border bg-blue-700'>
                                            <FaCheck size={13} className='absolute left-[15%] top-[20%] text-white' />
                                        </div>
                                    </div>
                                ) : null
                            }
                            <img src='./images/child_insurance_icon.png' alt='child_insurance_icon' width={50} height={50} className='my-2 bg-[#dff6f9] rounded-full' />
                            <span className="-mt-2.5 font-semibold">Child</span>
                        </div>
                    )}
                </RadioGroup.Option>
                <RadioGroup.Option value="Retirement" className="w-full h-20 px-2 shadow-sm shadow-gray-500/90 cursor-pointer hover:shadow-md hover:shadow-gray-500/90 bg-[#f9feff]">
                    {({ checked }) => (
                        <div className='relative flex flex-col justify-center items-center bg-transparent'>
                            {
                                checked ? (
                                    <div className='w-6 h-6 absolute -right-2 top-0 rounded-full bg-white'>
                                        <div className='w-5 h-5 absolute right-0.5 top-0.5 rounded-full box-border bg-blue-700'>
                                            <FaCheck size={13} className='absolute left-[15%] top-[20%] text-white' />
                                        </div>
                                    </div>
                                ) : null
                            }
                            <img src='./images/retirement_icon.png' alt='retirement_icon' width={50} height={50} className='my-2 bg-[#dff6f9] rounded-full' />
                            <span className="-mt-2.5 font-semibold">Retirement</span>
                        </div>

                    )}
                </RadioGroup.Option>
            </RadioGroup>
            <small className='mt-4 text-md text-red-500'>
                {
                    !typeState ? formErrors.type : (!['Term', 'Endowment'].includes(typeState)) ? formErrors.type_err : ''
                }
            </small>
            <div className='px-3 mt-3 flex justify-center items-center'>
                <button className="w-1/2 mx-auto flex justify-center gap-2 text-lg p-2 shadow-md shadow-gray-600/90 
            rounded-xl text-white bg-orange-500 hover:bg-orange-700 transition duration-300"
                    onClick={handleClick}
                >
                    Check Prices
                    <FaArrowRightLong size={20} className='mt-1' />
                </button>
            </div>
        </div>
    );
};

export default LifeTab;