import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FaUserCircle } from "react-icons/fa";
import { MdEdit } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { setTermTabState } from '../../../redux/features/lifeTab/lifeTabSlice';
import { API_GET_LIFE_INSURANCE_QUOTE } from '../../../config/ApiList';
import { ThreeCircles } from 'react-loader-spinner';
import useSWRFetch from '../../../hooks/useSWRFetch';
import { setEbiResponse, setTermState, setTypeState } from '../../../redux/features/life/lifeSlice';

const userInfoStyle = `px-3 border-r border-gray-500`;
const termDataStyle = `w-1/4 h-full flex flex-col justify-center items-center`;

const policy_icon = '/images/policy_icons/ICICI_Prudential.avif';

const TermInsuranceQuotes = (props) => {
    const { quotationId } = useParams();
    const url = `${API_GET_LIFE_INSURANCE_QUOTE}/${quotationId}`;
    const { data, isLoading } = useSWRFetch(url);
    const termState = useSelector(state => state.life.termState);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [eBIResponse, setEBIResponse] = useState({});
    const eBIResponseTemp = data?.data;
    const lifeInsuranceData = data?.lifeInsuranceData;

    useEffect(() => {
        if (!isLoading) {
            const ebiResponseData = JSON.parse(eBIResponseTemp);
            setEBIResponse(ebiResponseData);
            dispatch(setEbiResponse(ebiResponseData));
            dispatch(setTypeState(lifeInsuranceData.lim_type));
            dispatch(setTermState({
                ...termState,
                fullName: lifeInsuranceData.lim_full_name,
                dob: lifeInsuranceData.lim_dob,
                email: lifeInsuranceData.lim_email,
                mobile: lifeInsuranceData.lim_phone,
                gender: lifeInsuranceData.lim_gender,
                maritalStatus: 'Single',
                occupationType: lifeInsuranceData.lim_occupation_type,
                incomeSelection: lifeInsuranceData.lim_income,
                educationalQualification: lifeInsuranceData.lim_education,
                smokeDisclaimer: lifeInsuranceData.lim_smoke_disclaimer,
                sumAssured: lifeInsuranceData.lim_sum_assured,
                modeOfPayment: lifeInsuranceData.lim_mode_of_payment,
                premiumPaymentTerm: lifeInsuranceData.lim_premium_payment_term,
            }));
        }
    }, [isLoading, eBIResponseTemp, lifeInsuranceData, dispatch]);

    const editDetails = () => {
        navigate("/life-insurance/term-insurance");
        dispatch(setTermTabState('BASIC'));
    };

    const viewDetails = (productCode) => {
        navigate(`/life-insurance/term-insurance/insurance-details/${quotationId}/${productCode}`);
    };

    return (
        <>
            {
                isLoading ? (
                    <div className='w-full h-full relative'>
                        <div className='fixed z-50 inset-0 bg-gradient-to-r from-[#fffdf8] via-[#fcf1cd] to-[#fdd7a2]'>
                            <div className='w-full h-full flex justify-center items-center'>
                                <ThreeCircles
                                    visible={isLoading}
                                    height="50"
                                    width="50"
                                    outerCircleColor="#f97316"
                                    middleCircleColor="transparent"
                                    innerCircleColor="#1d4fd8"
                                    ariaLabel="three-circles-loading"
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='w-full min-h-[calc(100vh-6rem)]'>
                        <div className='w-full h-10 px-10 py-2 bg-white border-b shadow-sm'>
                            <div className='text-sm flex justify-end'>
                                <FaUserCircle size={25} color='skyblue' />
                                <span className={`${userInfoStyle}`}>{eBIResponse?.FirstName} {' '} {eBIResponse?.LastName}</span>
                                <span className={`${userInfoStyle}`}>{props.calculateAge(eBIResponse?.DateOfBirth)} yrs</span>
                                <span className={`${userInfoStyle}`}>{eBIResponse?.Gender}</span>
                                <span className={`${userInfoStyle}`}>
                                    {eBIResponse?.PremiumSummary?.Tobacco === "1" ? "Smoker" : "Non-smoker"}
                                </span>

                                <button
                                    className='flex gap-1 ml-3 text-blue-700 hover:underline'
                                    onClick={() => editDetails()}
                                >
                                    <MdEdit className='w-[15px] h-[15px] mt-1' />
                                    <span className='text-base'>Edit</span>
                                </button>
                            </div>
                        </div>
                        <div className='w-full min-h-full px-10 py-2 mt-5'>
                            <div className='w-3/4 h-40 p-5 mb-5 mx-auto flex gap-5 bg-[#fff4e7] rounded-md shadow-sm shadow-gray-700'>
                                <div className={`${termDataStyle}`}>
                                    <img src={`${policy_icon}`} alt='' width={175} className='block' />
                                    <span className='text-gray-400 text-center mt-4'>{eBIResponse?.PremiumSummary?.ProductName}</span>
                                </div>
                                <div className='w-1/2 p-4 flex flex-col gap-y-2 justify-center'>
                                    <div className="flex">
                                        <span className='font-nunito'>Policy Term: &nbsp;</span>
                                        <span>{eBIResponse?.PremiumSummary?.Term} years</span>
                                        <span className='text-gray-500'>&nbsp;(Pay for <span className='font-bold text-gray-800'>{eBIResponse?.PremiumSummary?.Term}</span> years)</span>
                                    </div>
                                    <div className="flex">
                                        <span className='font-nunito'>Death Benefits: &nbsp;</span>
                                        <span>{`₹ ${props.getCurrencyWithStringFormat(eBIResponse?.PremiumSummary?.DeathBenefit)}`}</span>
                                        <span className='text-gray-500 font-poppins'>&nbsp;({eBIResponse?.PremiumSummary?.DeathBenefitOption} Payout)</span>
                                    </div>
                                    <div className="flex">
                                        <span className='font-nunito'>Maturity Benefits: &nbsp;</span>
                                        {/* <span>{`${props.getCurrencyFormat(eBIResponse?.PremiumSummary?.GuaranteedMaturityBenefit10)}`}</span> */}
                                        <span className='text-gray-500'>N/A</span>
                                    </div>
                                </div>
                                <div className="w-[calc(100%-(25%+50%))] flex flex-col justify-center items-end">
                                    <span className='text-xl'>{`${props.getCurrencyWithStringFormat(eBIResponse?.PremiumSummary?.TotalPremium)}`}</span>
                                    <span className='text-xs text-gray-500 mb-4'>{`${eBIResponse?.PremiumSummary?.ModeOfPayment} Premium`}</span>
                                    <button
                                        onClick={() => viewDetails(eBIResponse?.PremiumSummary?.ProductCode)}
                                        className='text-sm p-3 rounded-md bg-blue-700 text-white hover:bg-blue-800/95 transition duration-300'
                                    >
                                        View Details
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default TermInsuranceQuotes;