import React from 'react'
import HealthDetails from '../../components/healthDetails/HealthDetails'

const HealthPages = (props) => {
    return (
        <div>
            <HealthDetails {...props} />
        </div>
    )
}

export default HealthPages