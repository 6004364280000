import MemberSelection from './MemberSelection'
import { useSelector } from 'react-redux'

const HealthDetails = (props) => {
    const tabState = useSelector(state => state.tab.tabState)

    return (
        <div className='w-full min-h-[calc(100vh-6rem)] p-8'>
            <div className='w-full h-full px- py-4 flex gap-3 justify-center'>
                <div className='w-1/4 h-full p-5 mt-5 flex flex-col gap-y-5 items-center'>
                    <div className={`w-4/5 h-14 flex gap-3 items-center border-r-2 ${tabState === 'MEMBER' ? "border-orange-500" : "border-transparent"} `}>
                        <div className='w-5 h-5 flex justify-center items-center rounded-full bg-blue-700 text-white'>
                            1
                        </div>
                        <span>
                            Member Selection
                        </span>
                    </div>
                    <div className={`w-4/5 h-14 flex gap-3 items-center border-r-2 ${tabState === 'AGE' ? "border-orange-500" : "border-transparent"} `}>
                        <div className='w-5 h-5 flex justify-center items-center rounded-full bg-blue-700 text-white'>
                            2
                        </div>
                        <span>
                            Age Selection
                        </span>
                    </div>
                    <div className={`w-4/5 h-14 flex gap-3 items-center border-r-2 ${tabState === 'MEDICAL' ? "border-orange-500" : "border-transparent"} `}>
                        <div className='w-5 h-5 flex justify-center items-center rounded-full bg-blue-700 text-white'>
                            3
                        </div>
                        <span>
                            Medical History
                        </span>
                    </div>
                    <div className={`w-4/5 h-14 flex gap-3 items-center border-r-2 ${tabState === 'CONTACT' ? "border-orange-500" : "border-transparent"} `}>
                        <div className='w-5 h-5 flex justify-center items-center rounded-full bg-blue-700 text-white'>
                            4
                        </div>
                        <span>
                            Contact Details
                        </span>
                    </div>
                </div>

                <div className='w-1/2 h-full p-5 rounded-md bg-white shadow-md shadow-gray-700'>
                    <MemberSelection {...props} />
                </div>
            </div>
        </div>
    )
}

export default HealthDetails