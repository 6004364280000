import React from 'react';
import TermInsuranceQuotes from '../../../components/termInsurance/termInsuranceQuotes/TermInsuranceQuotes';

const TermInsurancePage = (props) => {
    return (
        <div>
            <TermInsuranceQuotes {...props} />
        </div>
    );
};

export default TermInsurancePage;