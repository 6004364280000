import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    memberState: []
};

const memberSlice = createSlice({
    name: 'member',
    initialState,
    reducers: {
        resetState: (state) => {
            state.memberState = initialState.memberState;
        },
        addMember: (state, action) => {
            state.memberState.push(action.payload);
        },
        removeMember: (state, action) => {
            state.memberState = state.memberState.filter(member => member.id !== action.payload.id);
        },
        setAge: (state, action) => {
            state.memberState = action.payload;
        },
    },
});

export const { resetState, addMember, removeMember, setAge } = memberSlice.actions;
export default memberSlice.reducer;