import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    appInfo: {
        appNo: "",
        advisorCode: "",
        source: "NIVESHPAYBOL",
        sourceKey: "NIVESHPAYBOLKEY",
        salesDataReqd: "N",
        dependentFlag: "N",
        sourceTransactionId: "",
        sourceOfFund: "Salary",
        uidId: "37608",
        buyersPinCode: "400000",
        sellersPinCode: "400097",
        mwpaOpted: "No",
        mwpaBenefit: "",
    },
    proposerInfos: {
        aadhaarOptionSelected: "Aadhaar No",
        frstNm: "Vipul",
        lstNm: "Jain",
        mrtlSts: "696",
        dob: "16-Aug-1986",
        gender: "Male",
        isStaff: "0",
        dateOfCommencementOfLoan: "",
        mobNo: "9999999999",
        relationWithLa: "Spouse",
        sharePortfolio: "No",
        fathersName: "Adam",
        mothersName: "Eve",
        spouseName: "Alice",
        ckycNumber: "12345678901234",
        kycDoc: {
            idPrf: "Voters ID",
            addPrf: "Passport",
            agePrf: "ANM",
            itPrf: "pancard",
            incomePrf: "ITRETN",
            lddIdOthrDesc: "222222",
            lddIdNumber: "DADPK3599E",
            lddIdExpiryDate: "11/21/2022",
        },
        comunctnAddress: {
            pincode: "999999",
            landmark: "LANDMARK 1",
            state: "37",
            line1: "ADD LINE 1",
            line3: "ADD LINE 3",
            city: "CHENNAI",
            country: "India",
            line2: "ADD LINE 2",
        },
        education: "676",
        prmntAddress: {
            pincode: "999999",
            landmark: "LANDMARK 1",
            state: "37",
            line1: "ADD LINE 1",
            line3: "ADD LINE 3",
            city: "CHENNAI",
            country: "India",
            line2: "ADD LINE 2",
        },
        nri: {
            passportNo: "P8803452",
            dateOfArrivingIndia: "07/07/2016",
            dateOfLeavingIndia: "09/07/2021",
            durationOfStayInYear: "10",
            durationOfStayInMonth: "6",
            nameOfEmployer: "TCS",
            travelDetails: "",
            bankType: "NRO",
            nriTINIssuingCountry: "India",
            countryOfResidence: "India",
            currentresidence: "Morocco",
            tinNum: "",
            tinNum2: "3435",
            tinNum3: "",
            nriBirthCountry: "Aruba",
            placeOfBirthNRI: "Mumbai",
            countryOfResidence2: "Morocco",
            countryOfResidence3: "",
            nriOtherResCountry: "No",
            countryOfNationality: "Aruba",
            nriTaxResidentUS: "No",
            purposeOfStay: "Empolyment",
            travelInLast1Year: "Yes",
            modeOfTravel: "Private",
            natureOfBusiness: "sdasd",
            intentedDurationStayInYear: "5",
            intentedDurationStay: "10",
            safetyPrecaution: "asdad",
        },
        occ: "PROF",
        currentlyInvestigated: "No",
        myProf: "CA",
        occDesc: "",
        indsType: "",
        indsTypeDesc: "",
        nameOfOrg: "",
        objective: "Both",
        annIncme: "1000000",
        panNo: "AMXPB9901C",
        photoSubmitted: "No",
        nationality: "Non Indian",
        email: "testuser@mail.com",
        pltclyExpsd: "No",
        rstSts: "Foreign National",
    },
    lifeAssrdInfos: {
        frstNm: "LAFIRSTNAME",
        lstNm: "LALASTNAME",
        dob: "16-April-1980",
        mrtlSts: "696",
        gender: "Female",
        pinCode: "999999",
        mobNo: "9999099990",
        nationality: "Indian",
        aadharCardNo: "822827727282",
        email: "testuser22@mail.com",
        education: "680",
        kycDoc: {
            idPrf: "Voters ID",
            addPrf: "Passport",
            agePrf: "ANM",
            itPrf: "pancard",
            incomePrf: "ITRETN",
            lddIdOthrDesc: "222222",
            lddIdNumber: "DADPK3599E",
            lddIdExpiryDate: "11/21/2022",
        },
        comunctnAddress: {
            pincode: "999999",
            landmark: "LANDMARK 1",
            state: "37",
            line1: "ADD LINE 1",
            line3: "ADD LINE 3",
            city: "CHENNAI",
            country: "India",
            line2: "ADD LINE 2",
        },
        prmntAddress: {
            pincode: "999999",
            landmark: "LANDMARK 1",
            state: "37",
            line1: "ADD LINE 1",
            line3: "ADD LINE 3",
            city: "CHENNAI",
            country: "India",
            line2: "ADD LINE 2",
        },
        nri: {
            passportNo: "P8803452",
            dateOfArrivingIndia: "07/07/2016",
            dateOfLeavingIndia: "09/09/2021",
            durationOfStayInYear: "10",
            durationOfStayInMonth: "6",
            nameOfEmployer: "TCS",
            travelDetails: "",
            bankType: "NRO",
            nriTINIssuingCountry: "India",
            countryOfResidence: "India",
            currentresidence: "Morocco",
            tinNum: "",
            tinNum2: "3435",
            tinNum3: "",
            nriBirthCountry: "Aruba",
            placeOfBirthNRI: "Mumbai",
            countryOfResidence2: "Morocco",
            countryOfResidence3: "",
            nriOtherResCountry: "No",
            countryOfNationality: "Aruba",
            nriTaxResidentUS: "No",
            purposeOfStay: "Empolyment",
            travelInLast1Year: "Yes",
            modeOfTravel: "Private",
            natureOfBusiness: "sdasd",
            intentedDurationStayInYear: "",
            intentedDurationStay: "",
            safetyPrecaution: "asdad",
        },
        desig: "",
        occ: "PROF",
        occDesc: "",
        myProf: "CA",
        nameOfOrg: "",
        rstSts: "PIO",
        currentlyInvestigated: "No",
        annIncme: "500000",
    },
    healthDetails: [
        {
            question: 'Height (Ft/ Inches)',
            answer1: "5",
            code: "HQ01",
            answer3: "",
            answer2: "5 feet 11 inches",
            answer4: ""
        },
        {
            question: 'Height (Cm)',
            answer1: "11",
            code: "HQ02",
            answer3: "",
            answer2: "180",
            answer4: ""
        },
        {
            question: 'Weight (Kilograms)',
            answer1: "74",
            code: "HQ03",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: 'Do you consume or have ever consumed tobacco?',
            answer1: "No",
            code: "HQ05",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: 'Do you consume or have ever consumed Alcohol?',
            answer1: "No",
            code: "HQ06",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: 'Is your occupation associated with any specific hazard or do you take part in activities or have hobbies that could be dangerous in any way ? (eg - occupation - Chemical factory, mines, explosives, radiation, corrosive chemicals j - aviation other than as a fare paying passenger, diving, mountaineering, any form of racing, etc)',
            answer1: "No",
            code: "HQ09",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: 'Are you employed in the armed, para military or police forces ?(If yes, please provide Rank, Department/Division, Date of last medical & category after medical exam).',
            answer1: "No",
            code: "HQ125",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: 'Have you lost weight of 10 kgs or more in the last six months?',
            answer1: "No",
            code: "HQ144",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: 'Family details of the life assured(include parents/sibling) Are any of your family members suffering from /have suffered from/have died of heart disease,Diabetes Mellitus, cancer or any other hereditary/familial disorder, before 55 years of age.if yes please provide details below.',
            answer1: "No",
            code: "HQ165",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: 'Have you undergone or been advised to undergo any tests/investigations or any surgery or hospitalized for observation or treatment in the past?',
            answer1: "No",
            code: "HQ166",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: 'Have you ever suffered or being diagnosed with or been treated for any of the following? (If answer 15 is "Yes", at least one of the 14 answers to be passed as "Yes", then remarks and nested question 9.a. need to be passed)',
            answer15: "No",
            answer1: "No",
            code: "HQ167",
            answer3: "No",
            answer2: "No",
            answer4: "No",
            answer5: "No",
            answer6: "No",
            answer7: "No",
            answer8: "No",
            answer9: "No",
            answer10: "No",
            answer11: "No",
            answer12: "No",
            answer13: "No",
            answer14: "No"
        },
        {
            question: 'Do you have any congenital defect/abnormality/physical deformity/handicap?',
            answer1: "No",
            code: "HQ21",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: 'Did you have any ailment/injury/accident requiring treatment/medication for more than a week or have you availed leave for more than 5 days on medical grounds in the last two years?',
            answer1: "No",
            code: "HQ24",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: 'Have you ever suffered/are suffering from or have undergone investigations or treatment for any gynecological complications such as disorders of cervix,uterus,ovaries,breast, breast lump,cyst etc.',
            answer1: "No",
            code: "HQ168",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: 'Are you pregnant at present?',
            answer1: "No",
            code: "HQ61",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: '',
            answer1: "No",
            code: "HQ257",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: '',
            answer1: "No",
            code: "HQ258",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: '',
            answer1: "No",
            code: "CHQ94",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: '',
            answer1: "No",
            code: "CHQ95",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: '',
            answer1: "No",
            code: "CHQ96",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: 'Do you work in an occupation like health care worker/Corona warrior Include (General Practitioners, Doctors, Hospital Doctors, Surgeons, Therapists, Nurses, Pathologist, paramedics, Pharmacist, Ward helpers, Individuals working in Hospitals/ Clinics having novel coronavirus (SARS-CoV-2/COVID-19) Ward ?)  where you have a higher risk to get in close contact with COVID-19 patients or with coronavirus contaminated material?',
            answer1: "No",
            code: "CHQ21",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: 'In the last 1 months have you or any of your family member been self-isolated with symptoms on medical advice?(excluding mandatory government orders to remain at home)',
            answer1: "No",
            code: "CHQ19",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: 'In the last 1 month did you have persistent cough, fever ,sore throat, nausea, vomiting ,diarrhea, difficulty in breathing ,loss of smell and taste any other symptoms of coronavirus (COVID-19) and advised to do a Covid test or you/your family member have been in contact with an individual suspected or confirmed  to have COVID-19? ',
            answer1: "No",
            code: "CHQ20",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: 'Have you been vaccinated for COVID19 ?',
            answer1: "No",
            code: "CHQ30",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: 'Have you ever been tested positive for COVID19 ?',
            answer1: "No",
            code: "CHQ14",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: 'Have you travelled abroad in past 14 days ?',
            answer1: "No",
            code: "CHQ43",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: 'Do you consume or have ever consumed Narcotics ?',
            answer1: "No",
            code: "HQ07",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: 'Do you intend to travel abroad in next 3 month ?',
            answer1: "No",
            code: "CHQ56",
            answer3: "",
            answer2: "",
            answer4: ""
        },
        {
            question: 'Are your currently residing outside of India ?',
            answer1: "No",
            code: "CHQ38",
            answer3: "",
            answer2: "",
            answer4: ""
        }
    ],
    productSelectionE28: {
        selectedOption: "false",
        specialDate: "",
        modalPremium: "150000",
        annualPremium: "0",
        sumAssured: "1500000",
        totalPremium: "156750",
        maturityAmnt: "",
        reverseCalculation: "N",
        premiumPayingTerm: "10",
        pensionFrequency: "",
        saMultiple: "10",
        deathBenefitOption: "Lump-Sum",
        premiumPayingFrequency: "Yearly",
        policyTerm: "15",
        payoutTerm: "",
        productType: "TRADITIONAL",
        productName: "ICICI Pru Guaranteed Income For Tomorrow",
        productId: "E28",
        premiumpaymentoption: "Limited Pay",
        rider1: {
            name: "ADBV",
            sumAssured: "0",
            term: "0"
        },
        rider2: {
            name: "ATPD",
            sumAssured: "0",
            term: "0"
        },
        Occupation: "SPVT",
        isKeralaCess: "false"
    },
    productSelectionE34: {
        selectedOption: "false",
        modalPremium: "100000",
        annualPremium: "100000",
        sumAssured: "1050000",
        totalPremium: "30000",
        maturityAmnt: "",
        reverseCalculation: "N",
        premiumPayingTerm: "10",
        pensionFrequency: "Yearly",
        saMultiple: "10.5",
        deathBenefitOption: "Income",
        premiumPayingFrequency: "Yearly",
        policyTerm: "23",
        payoutTerm: "13",
        productType: "TRADITIONAL",
        productName: "ICICI Pru Sukh Samruddhi",
        productId: "E34",
        premiumpaymentoption: "Limited Pay",
        isKeralaCess: "false",
        portfolioStrategy: "0",
        RetirementAge: "0",
        spouseDeathBenefit: "0",
        spouseOpted: "0",
        Option: "0",
        tobaccoJL: "0",
        homeLoan: "0",
        salesChannel: "7",
        loanTenure: "0",
        cashBenefitMode: "0",
        modeOfIncome: "0",
        CIBenefit: "0",
        spouseCIBenefit: "0",
        spouseLumpsumPercentage: "0",
        familyBenefit: "false",
        loyaltyBenefit: "false"
    },
    nomineeInfos: {
        frstNm: "John",
        relationship: "Brother",
        gender: "Male",
        dob: "26-May-1987",
        lstNm: "Jain",
        apnteDtls: {
            frstNm: "",
            relationship: "",
            gender: "",
            dob: "",
            lstNm: ""
        }
    },
    eiaDetails: {
        isEIAOpted: "No",
        eiaInsuranceRepository: "NDML",
        EIAAccountNumber: ""
    },
    advisorSalesDetails: {
        channelType: "W2",
        cusBankAccNo: "",
        bankName: "SAMA",
        needRiskProfile: "",
        csrLimCode: "5032212",
        cafosCode: "999999999",
        oppId: "",
        fscCode: "01286361",
        spCode: "",
        bankBrnch: "ZV11",
        source: "SAMI",
        lanNo: "",
        selectedTab: "broker_ci",
        subChannel: ""
    }
};

const iciciBolE28E34Slice = createSlice({
    name: 'iciciBolE28E34',
    initialState,
    reducers: {
        setAppInfoState: (state, actions) => {
            return {
                ...state,
                appInfo: actions.payload
            };
        },
        setProposerInfoState: (state, actions) => {
            return {
                ...state,
                proposerInfos: actions.payload
            };
        },
        setLifeAssrdInfosState: (state, actions) => {
            return {
                ...state,
                lifeAssrdInfos: actions.payload
            };
        },
        setHealthDetailsState: (state, actions) => {
            return {
                ...state,
                healthDetails: actions.payload
            };
        },
        setProductSelectionE28State: (state, actions) => {
            return {
                ...state,
                productSelectionE28: actions.payload
            };
        },
        setProductSelectionE34State: (state, actions) => {
            return {
                ...state,
                productSelectionE34: actions.payload
            };
        },
        setNomineeInfosState: (state, actions) => {
            return {
                ...state,
                nomineeInfos: actions.payload
            };
        },
        setEiaDetailsState: (state, actions) => {
            return {
                ...state,
                eiaDetails: actions.payload
            };
        },
        setAdvisorSalesDetailsState: (state, actions) => {
            return {
                ...state,
                advisorSalesDetails: actions.payload
            };
        },
    },
});

export const {
    setAppInfoState,
    setProposerInfoState,
    setLifeAssrdInfosState,
    setHealthDetailsState,
    setProductSelectionE28State,
    setProductSelectionE34State,
    setNomineeInfosState,
    setEiaDetailsState,
    setAdvisorSalesDetailsState,
} = iciciBolE28E34Slice.actions;

export default iciciBolE28E34Slice.reducer;