const policy_icon1 = '/images/policy_icons/ManipalCigna.webp';
const policy_icon2 = '/images/policy_icons/HDFC_ERGO.webp';
const policy_icon3 = '/images/policy_icons/Reliance.webp';
const policy_icon4 = '/images/policy_icons/Bajaj_Allianz.webp';
const policy_icon5 = '/images/policy_icons/Tata_AIG.webp';

const policyArr = [
    {
        id: 1,
        name: "Family Health Protector",
        imgUrl: `${policy_icon1}`,
        monthlyAmount: "2325",
        annualAmount: "27,895",
    },
    {
        id: 2,
        name: "Optima Secure",
        imgUrl: `${policy_icon2}`,
        monthlyAmount: "2916",
        annualAmount: "34,988",
    },
    {
        id: 3,
        name: "Health Gain",
        imgUrl: `${policy_icon3}`,
        monthlyAmount: "2086",
        annualAmount: "25,024",
    },
    {
        id: 4,
        name: "Bajaj Health Guard - Platinum",
        imgUrl: `${policy_icon4}`,
        monthlyAmount: "3004",
        annualAmount: "36,048",
    },
    {
        id: 5,
        name: "Medicare Premier",
        imgUrl: `${policy_icon5}`,
        monthlyAmount: "2747",
        annualAmount: "32,958",
    },
];

export default policyArr;