const policy_icon1 = '/images/policy_icons/HDFC_ERGO.webp';
const policy_icon2 = '/images/policy_icons/SBI_General.avif';
const policy_icon3 = '/images/policy_icons/Bajaj_Allianz.webp';
const policy_icon4 = '/images/policy_icons/National_Insurance.avif';
const policy_icon5 = '/images/policy_icons/New_India_Assurance.avif';
const policy_icon6 = '/images/policy_icons/Reliance.webp';
const policy_icon7 = '/images/policy_icons/Iffco_Tokio.avif';

const carArr = {
    thirdParty: [
        {
            id: 1,
            imgUrl: `${policy_icon1}`,
            premiumAmount: "3,416",
            planType: "Third Party",
        },
        {
            id: 2,
            imgUrl: `${policy_icon2}`,
            premiumAmount: "3,225",
            planType: "Third Party",
        },
        {
            id: 3,
            imgUrl: `${policy_icon3}`,
            premiumAmount: "3,759",
            planType: "Third Party",
        },
        {
            id: 4,
            imgUrl: `${policy_icon5}`,
            premiumAmount: "3,120",
            planType: "Third Party",
        },
        {
            id: 5,
            imgUrl: `${policy_icon4}`,
            premiumAmount: "3,477",
            planType: "Limited Third Party",
        },
    ],
    comprehensive: [
        {
            id: 1,
            imgUrl: `${policy_icon6}`,
            idvAmount: "78,058",
            premiumAmount: "4,242",
            planType: "Comprehensive",
        },
        {
            id: 2,
            imgUrl: `${policy_icon4}`,
            idvAmount: "97,992",
            premiumAmount: "4,021",
            planType: "Comprehensive",
        },
        {
            id: 3,
            imgUrl: `${policy_icon1}`,
            idvAmount: "77,416",
            premiumAmount: "3,865",
            planType: "Comprehensive",
        },
        {
            id: 4,
            imgUrl: `${policy_icon5}`,
            idvAmount: "1,24,875",
            premiumAmount: "5,620",
            planType: "Comprehensive",
        },
        {
            id: 5,
            imgUrl: `${policy_icon7}`,
            idvAmount: "99,705",
            premiumAmount: "4,647",
            planType: "Comprehensive",
        },
    ],
};

export default carArr;