import React from 'react'
import carArr from '../../../static/carData'
import { FaArrowRightLong } from 'react-icons/fa6';

const CarDetailQuotes = (props) => {
    return (
        <div className='w-full mt-5 rounded-xl bg-white shadow-md shadow-gray-500/50'>
            <div className='p-5'>
                <div className='flex flex-col lg:px-10'>
                    <span className='text-sm md:text-base lg:text-lg lg:ml-[12.5%]'>Showing {carArr.thirdParty.length} {carArr.thirdParty[0].planType} plan(s)</span>
                </div>
                <div className='w-full min-h-full py-2 mt-5 lg:px-10'>
                    {
                        carArr.thirdParty.map((carData) => {
                            return (
                                <div key={carData.id} className='w-full min-h-10 mb-5 mx-auto flex flex-col bg-[#fff4e7] rounded-md shadow-sm shadow-gray-700 sm:h-20 sm:px-5 sm:flex-row lg:w-3/4 lg:h-20 lg:px-5 lg:flex-row'>
                                    <div className='w-full min-h-10 border-b border-gray-300 flex justify-center items-center sm:h-full sm:border-none lg:w-1/4 lg:h-full lg:border-none'>
                                        <img src={carData.imgUrl} alt='' className='block w-[80px] lg:w-[100px]' />
                                    </div>
                                    <div className='w-full h-10 flex flex-col justify-center items-center sm:h-full lg:w-2/5 lg:h-full'>
                                        <span className='text-xs text-gray-500 md:text-sm lg:text-sm'>{carData.planType} </span>
                                    </div>
                                    <div className='w-full h-full py-2 flex justify-center items-center lg:w-[calc(100%-(25%+40%))] lg:h-full lg:py-0'>
                                        <button
                                            className='p-1.5 flex gap-2 rounded-lg text-xs text-white bg-orange-500 hover:bg-orange-600/95 transition duration-300 sm:p-3 lg:p-3 lg:text-base'>
                                            ₹ {carData.premiumAmount}
                                            <FaArrowRightLong size={15} className='mt-0.5 md:mt-0 lg:mt-1' />
                                        </button>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>

                <div className='flex flex-col lg:px-10'>
                    <span className='text-sm md:text-base lg:text-lg lg:ml-[12.5%]'>Showing {carArr.comprehensive.length} {carArr.comprehensive[0].planType} plan(s)</span>
                </div>
                <div className='w-full min-h-full py-2 mt-5 lg:px-10'>
                    {
                        carArr.comprehensive.map((carData) => {
                            return (
                                <div key={carData.id} className='w-full min-h-10 mb-5 mx-auto flex flex-col bg-[#fff4e7] rounded-md shadow-sm shadow-gray-700 sm:h-20 sm:px-5 sm:flex-row lg:w-3/4 lg:h-20 lg:px-5 lg:flex-row'>
                                    <div className='w-full min-h-10 border-b border-gray-300 flex justify-center items-center sm:h-full sm:border-none lg:w-1/4 lg:h-full lg:border-none'>
                                        <img src={carData.imgUrl} alt='' width={100} className='block w-[80px] lg:w-[100px]' />
                                    </div>
                                    <div className='w-full h-10 flex flex-col justify-center items-center sm:h-full lg:w-2/5 lg:h-full'>
                                        <span className='text-xs text-gray-500 md:text-sm lg:text-sm'>{carData.planType} </span>
                                        <small className='text-gray-500'>IDV Cover &nbsp;<span className='text-xs font-semibold font-nunito text-black lg:text-lg'>₹ {carData.idvAmount}</span></small>
                                    </div>
                                    <div className='w-full h-full py-2 flex justify-center items-center lg:w-[calc(100%-(25%+40%))] lg:h-full lg:py-0'>
                                        <button className='p-1.5 flex gap-2 rounded-lg text-xs text-white bg-orange-500 hover:bg-orange-600/95 transition duration-300 sm:p-3 lg:p-3 lg:text-base'>
                                            ₹ {carData.premiumAmount}
                                            <FaArrowRightLong size={15} className='mt-0.5 md:mt-0 lg:mt-1' />
                                        </button>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default CarDetailQuotes