import { Tab } from '@headlessui/react';
import CarTab from '../tabs/CarTab';
import BikeTab from '../tabs/BikeTab';
import LifeTab from '../tabs/LifeTab';
import HealthTab from '../tabs/HealthTab';

const car_icon = '/images/icons/car.png';
const bike_icon = '/images/icons/bike.png';
const health_icon = '/images/icons/health.png';
const life_icon = '/images/icons/family.png';
const currency_icon = '/images/currency.png';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}
const Hero = (props) => {
    return (
        <div className='w-full h-[calc(100vh-6rem)] px-2 py-5 flex'>
            <div className='w-full h-full flex gap-x-4 relative'>
                <div className='w-2/5 h-full p-3'>
                    <div className="w-full min-h-0 max-w-md mt-10 p-3 mx-auto">
                        <Tab.Group>
                            <Tab.List className='w-full flex justify-between space-x-1 rounded-xl p-1 gap-3' data-aos="fade-left" data-aos-duration="1500" data-aos-delay="100" data-aos-easing="linear">
                                <Tab className={({ selected }) =>
                                    classNames(
                                        'w-full rounded-sm py-2.5 text-md shadow-sm font-semibold leading-5 transition duration-500',
                                        selected
                                            ? 'bg-transparent rounded-md shadow-xl text-blue-700 border-b-4 border-orange-500  outline-none'
                                            : 'shadow-gray-600/70 hover:shadow-lg hover:border-b-2 hover:border-gray-400/60'
                                    )
                                }
                                >
                                    <div className='flex gap-2 justify-center items-center'>
                                        <img src={car_icon} alt='' width={20} height={20} />
                                        <span>Car</span>
                                    </div>
                                </Tab>
                                <Tab className={({ selected }) =>
                                    classNames(
                                        'w-full rounded-sm py-2.5 text-md shadow-sm font-semibold leading-5 transition duration-500',
                                        selected
                                            ? 'bg-transparent rounded-md shadow-xl text-blue-700 border-b-4 border-orange-500  outline-none'
                                            : 'shadow-gray-600/70 hover:shadow-lg hover:border-b hover:border-gray-400/60'
                                    )
                                }
                                >
                                    <div className='flex gap-2 justify-center items-center'>
                                        <img src={bike_icon} alt='' width={20} height={20} />
                                        <span>Bike</span>
                                    </div>
                                </Tab>
                                <Tab className={({ selected }) =>
                                    classNames(
                                        'w-full rounded-sm py-2.5 text-md shadow-sm font-semibold leading-5 transition duration-500',
                                        selected
                                            ? 'bg-transparent rounded-md shadow-xl text-blue-700 border-b-4 border-orange-500  outline-none'
                                            : 'shadow-gray-600/70 hover:shadow-lg hover:border-b-2 hover:border-gray-400/60'
                                    )
                                }
                                >
                                    <div className='flex gap-2 justify-center items-center'>
                                        <img src={health_icon} alt='' width={20} height={20} />
                                        <span>Health</span>
                                    </div>
                                </Tab>
                                <Tab className={({ selected }) =>
                                    classNames(
                                        'w-full rounded-sm py-2.5 text-md shadow-sm font-semibold leading-5 transition duration-500',
                                        selected
                                            ? 'bg-transparent rounded-md shadow-xl text-blue-700 border-b-4 border-orange-500  outline-none'
                                            : 'shadow-gray-600/70 hover:shadow-lg hover:border-b-2 hover:border-gray-400/60'
                                    )
                                }
                                >
                                    <div className='flex gap-2 justify-center items-center'>
                                        <img src={life_icon} alt='' width={20} height={20} />
                                        <span>Life</span>
                                    </div>
                                </Tab>
                            </Tab.List>
                            <Tab.Panels className="w-full min-h-0 mt-4 bg-white rounded-md shadow-md shadow-gray-500/90" data-aos="fade-up" data-aos-duration="1500" data-aos-easing="ease-in-sine">
                                <Tab.Panel>
                                    < CarTab {...props} />
                                </Tab.Panel>

                                <Tab.Panel>
                                    < BikeTab {...props} />
                                </Tab.Panel>

                                <Tab.Panel>
                                    <HealthTab {...props} />
                                </Tab.Panel>

                                <Tab.Panel>
                                    <LifeTab {...props} />
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                </div>
                <div className='w-[calc(100%-35%)] h-1/2 pl-5 py-5 mt-14 relative font-montserrat capitalize' data-aos="fade-up" data-aos-duration="1500" data-aos-easing="linear">
                    <h1 className='text-6xl leading-snug font-extrabold text-gray-500 '>the smarter way to</h1>
                    <div className='w-full text-6xl leading-snug font-extrabold flex'>
                        <span className='text-gray-500'>buy</span>&nbsp;
                        <div className='w-full px-2 rounded-l-lg bg-gradient-to-r from-orange-500 to-blue-700'>
                            <span className=' text-white uppercase'>Insurance</span>
                        </div>
                    </div>

                    <img src={currency_icon} alt='' width={250} height={50} className='absolute -right-2 top-[43%]' />
                </div>
            </div>

        </div >
    );
};

export default Hero;