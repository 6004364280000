import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    tabState: 'MEMBER'
}

const tabSlice = createSlice({
    name: 'tab',
    initialState,
    reducers: {
        setTabState: (state, actions) => {
            return {
                ...state,
                tabState: actions.payload
            }
        }
    },
})

export const { setTabState } = tabSlice.actions
export default tabSlice.reducer